import { PublicOrderModel } from "../../models/public/order.public.model";
import { BasePublicService } from "./base.public.service";

export class PublicOrderService extends BasePublicService {
  getOrder(orderId: string): Promise<PublicOrderModel> {
    const reqUrl = `${process.env.NEXT_PUBLIC_API_ROOT_PUBLIC}orders/${orderId}`;

    const req = fetch(reqUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return this.makeRequestWithFetch<PublicOrderModel>(req, reqUrl);
  }
}
