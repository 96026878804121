import { useQuery } from "react-query";
import { ActivityEntities, ActivityList } from "../models/activity.model";
import { ActivityService } from "../services/activity.service";
import { QueryOpts } from "../models/base.model";

export const useGetActivityForStore = (storeId: string, queryOpts: QueryOpts) =>
  useQuery<ActivityList, Error>(
    `activity_${storeId}_${JSON.stringify(queryOpts)}`,
    async () => {
      const activityService = new ActivityService();

      return activityService.getActivities(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetActivityForEntity = (
  storeId: string,
  entity: ActivityEntities,
  entityId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<ActivityList, Error>(
    `activity_${storeId}_${entity}_${entityId}_${JSON.stringify(queryOpts)}`,
    async () => {
      const activityService = new ActivityService();

      return activityService.getActivitiesForEntity(
        storeId,
        entity,
        entityId,
        queryOpts,
      );
    },
    {
      enabled: Boolean(storeId && entity && entityId),
    },
  );
