import { ReadOnlyUser } from "../user/user.model";
import { PaginatedResponse } from "../../../models/base.model";

export enum EntityEventTypes {
  All = "all",
  Account = "account",
  InboundStock = "inboundstock",
  ProductionLog = "productionlog",
  ProductionInput = "productioninput",
  Supplier = "supplier",
  Store = "store",
  Transaction = "transaction",
}

export type EntityEventListItem = {
  id: string;
  created_by: ReadOnlyUser;
  created_at: string;
  entity_type: EntityEventTypes;
  event_data: object;
  event_type_friendly: string;
  event_type: string;
  entity_id: string;
};

export type GetEventsResponse = PaginatedResponse<EntityEventListItem>;

export type EntityEventDetail = {
  id: string;
  created_by: ReadOnlyUser;
  created_at: string;
  event_data: object;
  snapshot?: object;
  event_type: string;
  event_type_friendly: string;
  entity: object;
  entity_id: string;
  entity_type: EntityEventTypes;
};

export type FilteringProperty = {
  label: string;
  value: string;
  count: number;
};

export type EntityEventFilteringResponse = {
  actors?: FilteringProperty[];
  entities?: FilteringProperty[];
  event_types?: FilteringProperty[];
};
