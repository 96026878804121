import { BaseService } from "../../../../services/base.service";
import { QueryOpts } from "../../../../models/base.model";
import {
  ChangeTransactionAccountDto,
  CreateTransactionDto,
  StoreTransactionTitlesResponse,
  Transaction,
  TransactionListResponse,
  UpdateTransactionDto,
} from "./transaction.model";
import { ApiClientResponse } from "../../../../models/responses";

export class TransactionService extends BaseService {
  getStoreTransactions(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<TransactionListResponse> {
    const reqUrl = `/transactions/${storeId}/`;

    const req = this.apiClient.get<ApiClientResponse<TransactionListResponse>>(
      reqUrl,
      {
        params: queryOpts.filters,
      },
    );

    return this.makeRequest<TransactionListResponse>(req, reqUrl);
  }

  getStoreTransactionTitles(
    storeId: string,
  ): Promise<StoreTransactionTitlesResponse> {
    const reqUrl = `/transactions/${storeId}/params/titles/`;

    const req =
      this.apiClient.get<ApiClientResponse<StoreTransactionTitlesResponse>>(
        reqUrl,
      );

    return this.makeRequest<StoreTransactionTitlesResponse>(req, reqUrl);
  }

  createTransaction(
    storeId: string,
    data: CreateTransactionDto,
  ): Promise<Transaction> {
    const reqUrl = `/transactions/${storeId}/`;
    const req = this.apiClient.post<ApiClientResponse<Transaction>>(
      reqUrl,
      data,
    );

    return this.makeRequest<Transaction>(req, reqUrl);
  }

  updateTransaction(
    storeId: string,
    transactionId: string,
    data: UpdateTransactionDto,
  ): Promise<Transaction> {
    const reqUrl = `/transactions/${storeId}/${transactionId}/`;
    const req = this.apiClient.patch<ApiClientResponse<Transaction>>(
      reqUrl,
      data,
    );

    return this.makeRequest<Transaction>(req, reqUrl);
  }

  chaneTransactionAccount(
    storeId: string,
    transactionId: string,
    data: ChangeTransactionAccountDto,
  ): Promise<Transaction> {
    const reqUrl = `/transactions/${storeId}/${transactionId}/change-account/`;
    const req = this.apiClient.post<ApiClientResponse<Transaction>>(
      reqUrl,
      data,
    );

    return this.makeRequest<Transaction>(req, reqUrl);
  }

  getTransaction(storeId: string, transactionId: string): Promise<Transaction> {
    const reqUrl = `/transactions/${storeId}/${transactionId}/`;
    const req = this.apiClient.get<ApiClientResponse<Transaction>>(reqUrl);

    return this.makeRequest<Transaction>(req, reqUrl);
  }
}
