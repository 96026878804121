import { PaginatedResponse, QueryOpts } from "../models/base.model";
import { useMutation, useQuery } from "react-query";
import { CreateOutletDto, Outlet } from "../models/outlet.model";
import { OutletService } from "../services/outlet.service";

export const useGetStoreOutletsQuery = (
  storeId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<PaginatedResponse<Outlet>, Error>(
    `getOutletsForStore${storeId}_${JSON.stringify(queryOpts?.filters)}`,
    () => {
      const outletService = new OutletService();
      return outletService.getOutletsForStore(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetOutletQuery = (storeId: string, outletId: string) =>
  useQuery<Outlet, Error>(
    `getOutlet_${storeId}_outletId`,
    () => {
      const outletService = new OutletService();
      return outletService.getOutlet(storeId, outletId);
    },
    {
      enabled: Boolean(storeId && outletId),
    },
  );

export const useCreateStoreOutletMutation = () =>
  useMutation<Outlet, Error, { data: CreateOutletDto; storeId: string }>(
    ({ data, storeId }) => {
      const outletService = new OutletService();
      return outletService.createOutletForStore(storeId, data);
    },
  );
