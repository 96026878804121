import { BaseService } from "./base.service";
import { ApiClientResponse } from "../models/responses";
import { PaginatedResponse, QueryOpts } from "../models/base.model";
import {
  CreateMaterialDto,
  Material,
  ShortMaterial,
  UpdateMaterialDto,
} from "../models/material.model";

export class MaterialService extends BaseService {
  createMaterial(storeId: string, data: CreateMaterialDto): Promise<Material> {
    const reqUrl = `materials/${storeId}/`;
    const req = this.apiClient.post<ApiClientResponse<Material>>(reqUrl, data);

    return this.makeRequest<Material>(req, reqUrl);
  }

  getMaterials(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<Material>> {
    const reqUrl = `materials/${storeId}/`;
    const req = this.apiClient.get<
      ApiClientResponse<PaginatedResponse<Material>>
    >(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest<PaginatedResponse<Material>>(req, reqUrl);
  }

  getAllMaterials(storeId: string): Promise<ShortMaterial[]> {
    const reqUrl = `materials/${storeId}/all/`;
    const req = this.apiClient.get<ApiClientResponse<ShortMaterial[]>>(reqUrl);

    return this.makeRequest<ShortMaterial[]>(req, reqUrl);
  }

  getMaterial(storeId: string, materialId: string): Promise<Material> {
    const reqUrl = `materials/${storeId}/${materialId}/`;
    const req = this.apiClient.get<ApiClientResponse<Material>>(reqUrl);

    return this.makeRequest<Material>(req, reqUrl);
  }

  updateMaterial(
    storeId: string,
    materialId: string,
    data: UpdateMaterialDto,
  ): Promise<Material> {
    const reqUrl = `materials/${storeId}/${materialId}/`;
    const req = this.apiClient.patch<ApiClientResponse<Material>>(reqUrl, data);

    return this.makeRequest<Material>(req, reqUrl);
  }
}
