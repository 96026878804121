import { Roarr, ROARR } from "roarr";

ROARR.write = (message) => {
  // eslint-disable-next-line no-console
  console.log(JSON.parse(message));
};

declare global {
  /* eslint-disable no-var */
  var X_ELEO_SERVICE_NAME: string;
  var X_ELEO_BUILD_ID: string;
  var __NEXT_DATA__: {
    buildId: string;
  };
  /* eslint-enable no-var */
}

export const setupEleoApp = ({ serviceName }: { serviceName: string }) => {
  globalThis.X_ELEO_SERVICE_NAME = serviceName;

  if (globalThis.__NEXT_DATA__) {
    globalThis.X_ELEO_BUILD_ID = globalThis.__NEXT_DATA__.buildId;
  }
};

export const logger = Roarr.child((message) => {
  return {
    ...message,
    context: {
      ...message.context,
      serviceName: globalThis.X_ELEO_SERVICE_NAME,
      buildId: globalThis.X_ELEO_BUILD_ID,
    },
  };
});
