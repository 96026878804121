import { FinancialServicesService } from "./financialServices.service";
import {
  BankAccountDetailResponse,
  GetBankResponse,
} from "./financialServices.model";
import { useQuery } from "react-query";

export const useGetBanks = () =>
  useQuery<GetBankResponse, Error>(`banks`, () => {
    const financialServicesService = new FinancialServicesService();
    return financialServicesService.getBanks();
  });

export const useGetBankAccountDetail = (
  bankCode: string,
  accountNumber: string,
) =>
  useQuery<BankAccountDetailResponse, Error>(
    ["bankAccountDetail", bankCode, accountNumber],
    () => {
      const financialServicesService = new FinancialServicesService();
      return financialServicesService.getBankAccountDetail(
        bankCode,
        accountNumber,
      );
    },
    {
      enabled: Boolean(bankCode && accountNumber),
    },
  );
