import { useQuery } from "react-query";
import { CApiStore } from "./store.ecommerce.model";
import { StoreEcommerceService } from "./store.ecommerce.service";

export const useCustomerApiGetStoreBySlug = (storeSlug: string) => {
  return useQuery<CApiStore, Error>(
    `capi_get_store_by_slug_${storeSlug}`,
    () => {
      return StoreEcommerceService.getStoreBySlug(storeSlug);
    },
  );
};
