import { useMutation, useQuery } from "react-query";
import {
  AddStoreCreditDto,
  PaymentFilter,
  PaymentItem,
  PaymentMethodType,
  StorePaymentListResponse,
  StorePaymentMethod,
} from "./payment.model";
import { PaymentService } from "./payment.service";
import { QueryOpts } from "../../../models/base.model";

export const useGetStorePaymentMethods = (
  storeId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<StorePaymentMethod[], Error>(
    `useGetStorePaymentMethods_${storeId}`,
    () => {
      const paymentService = new PaymentService();
      return paymentService.getStorePaymentMethods(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetAllPaymentMethodTypes = () =>
  useQuery<PaymentMethodType[], Error>(`getAllPaymentMethodTypes`, () => {
    const paymentService = new PaymentService();
    return paymentService.getAllPaymentMethodTypes();
  });

export const useGetStorePayments = (storeId: string, queryOpts: QueryOpts) =>
  useQuery<StorePaymentListResponse, Error>(
    `getPaymentsForStore${storeId}_${JSON.stringify(queryOpts)}`,
    () => {
      const paymentService = new PaymentService();
      return paymentService.getPaymentsForStore(
        storeId,
        queryOpts?.filters as PaymentFilter,
      );
    },

    {
      enabled: Boolean(storeId),
    },
  );

export const useConfirmPaymentMutation = () =>
  useMutation<PaymentItem, Error, { storeId: string; paymentId: string }>(
    ({ storeId, paymentId }) => {
      const paymentService = new PaymentService();
      return paymentService.confirmPayment(storeId, paymentId);
    },
  );

export const useEnableStoreCreditPaymentMethod = (storeId: string) =>
  useMutation<null, Error>(() => {
    const paymentService = new PaymentService();
    return paymentService.enableStoreCreditPaymentMethod(storeId);
  });
export const useAddStoreCredit = (storeId: string, customerId: string) =>
  useMutation<null, Error, AddStoreCreditDto>((data) => {
    const paymentService = new PaymentService();
    return paymentService.addStoreCreditToCustomer(
      storeId,
      customerId,
      data.amount,
    );
  });
