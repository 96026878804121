import { BaseService } from "../../../services/base.service";
import {
  CustomerApiProductSearchParams,
  CustomerProductSearchResponse,
} from "./search.ecommerce.model";

class BaseCustomerSearchService extends BaseService {
  productSearch({
    query,
    ...params
  }: CustomerApiProductSearchParams): Promise<CustomerProductSearchResponse> {
    let reqUrl = "search/product";

    const queryParams = new URLSearchParams();

    if (query) {
      queryParams.set("q", query);
    }

    for (const [key, value] of Object.entries(params)) {
      // Check if the value is an array (for facets or any other multi-value parameters)
      if (Array.isArray(value)) {
        // If it's an array, append each value separately
        for (const singleValue of value) {
          queryParams.append(key, singleValue);
        }
      } else {
        queryParams.set(key, value as string);
      }
    }

    reqUrl = `${reqUrl}?${queryParams.toString()}`;

    const req = this.prepFetchRequest(reqUrl);

    return this.makeRequestWithFetch<CustomerProductSearchResponse>(
      req,
      reqUrl,
    );
  }
}

export const SearchEcommerceService = new BaseCustomerSearchService({
  baseURL: process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
  allowUnauthenticated: true,
});
