import { darkTheme, styled } from "../../styles";
import Link from "next/link";
import React, { useRef } from "react";

export interface MobileNavProps {
  handleNavToggle: () => void;
}

const navItemsHeight = "40px";

const MobileNavContainer = styled("div", {
  alignItems: "center",
  backgroundColor: "$white",
  display: "flex",
  justifyContent: "center",
  padding: "$3",
  position: "sticky",
  top: "0",
  zIndex: "$eAbsolute",

  "@bp1a": {
    display: "none",
  },

  "@print": {
    display: "none",
  },
});

const Logo = styled("img", {
  height: navItemsHeight,
  width: "auto",

  [`.${darkTheme} &`]: {
    filter: "invert(0.7)",
  },
});

const MenuButton = styled("button", {
  alignItems: "center",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  display: "flex",
  height: navItemsHeight,
  left: "$3",
  position: "absolute",

  [`.${darkTheme} &`]: {
    filter: "invert(0.7)",
  },
});

const LogoContainer = styled("div", {});

export const MobileNav = ({ handleNavToggle }: MobileNavProps) => {
  const mobileNavRef = useRef<HTMLDivElement>(null);

  return (
    <MobileNavContainer ref={mobileNavRef}>
      <MenuButton type="button" onClick={handleNavToggle}>
        <img src="https://media.eleo.app/menu.png" alt="menu" />
      </MenuButton>

      <Link href={"/dashboard"} passHref>
        <LogoContainer>
          <Logo src="/images/logo.svg" alt="logo" />
        </LogoContainer>
      </Link>
    </MobileNavContainer>
  );
};
