import { useQuery } from "react-query";
import { SearchEcommerceService } from "./search.ecommerce.service";
import {
  CustomerApiProductSearchParams,
  CustomerProductSearchResponse,
} from "./search.ecommerce.model";

export const useSearchProductsCustomerApi = (
  searchParams: CustomerApiProductSearchParams,
  initialData?: CustomerProductSearchResponse | null | undefined,
) => {
  return useQuery<CustomerProductSearchResponse, Error>(
    `customer_search_${JSON.stringify(searchParams)}`,
    () => {
      return SearchEcommerceService.productSearch(searchParams);
    },
    {
      initialData: initialData || undefined,
    },
  );
};

export const useGetStoreProductsCustomerApi = (
  storeSlug: string,
  searchParams: CustomerApiProductSearchParams,
) => {
  return useQuery<CustomerProductSearchResponse, Error>(
    `customer_search_${JSON.stringify(searchParams)}`,
    () => {
      return SearchEcommerceService.productSearch({
        ...searchParams,
        store: storeSlug,
      });
    },
    {
      enabled: Boolean(searchParams?.query),
    },
  );
};
