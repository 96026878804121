import { useQuery } from "react-query";
import { GlobalSearchParams, SearchResponse } from "./search.model";
import { SearchService } from "./search.service";

export const useGlobalSearch = <T>(searchParams: GlobalSearchParams) =>
  useQuery<SearchResponse<T>, Error>(
    `search_${JSON.stringify(searchParams)}`,
    () => {
      const searchService = new SearchService();
      return searchService.search<T>(searchParams);
    },
  );
