import { QueryOpts } from "../../../../models/base.model";
import { useMutation, useQuery } from "react-query";
import {
  CreateStockHistoryReasonDto,
  StockHistoryFilter,
  StockHistoryListResponse,
  StockHistoryReason,
  UpdateStockReasonDto,
} from "./stockHistory.model";
import { StockHistoryService } from "./stockHistory.service";

export const useGetStockHistoryForStore = (
  storeId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<StockHistoryListResponse, Error>(
    ["getStockHistoryForStore", storeId, queryOpts],
    () => {
      const stockHistoryService = new StockHistoryService();
      return stockHistoryService.getStockHistoryForStore(
        storeId,
        queryOpts?.filters as StockHistoryFilter,
      );
    },

    {
      enabled: Boolean(storeId),
    },
  );

export const useGetStockHistoryReasonsForStore = (storeId: string) =>
  useQuery<StockHistoryReason[], Error>(
    ["getStockHistoryReasonsForStore", storeId],
    () => {
      const stockHistoryService = new StockHistoryService();
      return stockHistoryService.getStockHistoryReasonsForStore(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useCreateStockHistoryReasonForStore = (storeId: string) => {
  return useMutation<StockHistoryReason, Error, CreateStockHistoryReasonDto>(
    (data) => {
      const stockHistoryService = new StockHistoryService();
      return stockHistoryService.createStockHistoryReasonForStore(
        storeId,
        data,
      );
    },
  );
};

export const useUpdateStockHistoryReasonForStore = (storeId: string) => {
  return useMutation<StockHistoryReason, Error, UpdateStockReasonDto>(
    (data) => {
      const stockHistoryService = new StockHistoryService();
      return stockHistoryService.updateStockHistoryReasonForStore(
        storeId,
        data.id,
        data,
      );
    },
  );
};
