import { useMutation, useQuery } from "react-query";
import { QueryOpts } from "../models/base.model";
import {
  CreateMaterialDto,
  Material,
  UpdateMaterialDto,
} from "../models/material.model";
import { MaterialService } from "../services/material.service";

export const useCreateMaterial = (storeId: string) =>
  useMutation<Material, Error, { data: CreateMaterialDto }>(({ data }) => {
    const materialService = new MaterialService();
    return materialService.createMaterial(storeId, data);
  });

export const useUpdateMaterial = (storeId: string) =>
  useMutation<Material, Error, { materialId: string; data: UpdateMaterialDto }>(
    (data) => {
      const materialService = new MaterialService();
      return materialService.updateMaterial(
        storeId,
        data.materialId,
        data.data,
      );
    },
  );

export const useGetAllMaterials = (storeId: string) =>
  useQuery(
    `getAllMaterials`,
    () => {
      const materialService = new MaterialService();
      return materialService.getAllMaterials(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetMaterials = (storeId: string, queryOpts: QueryOpts) =>
  useQuery(
    `getMaterials_${JSON.stringify(queryOpts)}`,
    () => {
      const materialService = new MaterialService();
      return materialService.getMaterials(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetMaterial = (storeId: string, materialId: string) =>
  useQuery(
    `getMaterial_${materialId}}`,
    () => {
      const materialService = new MaterialService();
      return materialService.getMaterial(storeId, materialId);
    },
    {
      enabled: Boolean(storeId && materialId),
    },
  );
