import { BaseService } from "../../../services/base.service";
import { EcommerceProductItem } from "./product.ecommerce.model";

class BaseCustomerProductService extends BaseService {
  getProduct(slug: string): Promise<EcommerceProductItem> {
    const reqUrl = `inventory/product/${slug}`;
    const req = this.prepFetchRequest(reqUrl);

    return this.makeRequestWithFetch<EcommerceProductItem>(req, reqUrl);
  }
}

export const EcommerceProductService = new BaseCustomerProductService({
  baseURL: process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
  allowUnauthenticated: true,
});
