import { BaseService } from "../../../services/base.service";
import {
  EcommerceUser,
  EcommerceUserCreateDto,
  EcommerceUserUpdateDto,
} from "./user.ecommerce.models";
import { ApiClientResponse } from "../../../models/responses";

export const ecommerceAuthErrors = {
  USER_NOT_FOUND: "user/not-found",
};

class BaseEcommerceUserService extends BaseService {
  createUser(userDto: EcommerceUserCreateDto): Promise<EcommerceUser> {
    const reqUrl = `users/`;

    const req = this.apiClient.post<ApiClientResponse<EcommerceUser>>(
      reqUrl,
      userDto,
    );

    return this.makeRequest<EcommerceUser>(req, reqUrl);
  }

  getCurrentUser(): Promise<EcommerceUser> {
    const reqUrl = `users/me`;

    const req = this.apiClient.get<ApiClientResponse<EcommerceUser>>(reqUrl);

    return this.makeRequest<EcommerceUser>(req, reqUrl).catch((err) => {
      if (err?.response?.status === 404) {
        err.code = ecommerceAuthErrors.USER_NOT_FOUND;
        err.message = "User does not exist";
      }

      throw err;
    });
  }

  updateUser(updateUserDto: EcommerceUserUpdateDto): Promise<EcommerceUser> {
    const reqUrl = `user/me`;

    const req = this.apiClient.patch<ApiClientResponse<EcommerceUser>>(
      reqUrl,
      updateUserDto,
    );

    return this.makeRequest<EcommerceUser>(req, reqUrl);
  }
}

export const EcommerceUserService = new BaseEcommerceUserService({
  baseURL: process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
});
