import { useQuery } from "react-query";
import { EcommerceProductService } from "./product.ecommerce.service";
import { EcommerceProductItem } from "./product.ecommerce.model";
import { QueryOpts } from "../../../models/base.model";

export const useGetEcommerceProductQuery = (
  slug: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<EcommerceProductItem, Error>(
    `getCApiProduct${slug}`,
    () => {
      return EcommerceProductService.getProduct(slug);
    },
    {
      enabled: Boolean(slug),
      initialData: queryOpts?.initialData,
    },
  );
