import { User } from "../modules/suite/user/user.model";
import {
  ConfirmationResult,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { UserService } from "../modules/suite/user/user.service";
import { BaseService } from "./base.service";
import { logger } from "../libs/logger";

// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
export const firebaseAuthLoginErrorMessages: {
  [key: string]: string;
} = {
  "auth/invalid-email": "Email is invalid",
  "auth/user-disabled": "User is disabled",
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Password is invalid",
  "auth/email-already-in-use": "Email is already in use",
  "auth/operation-not-allowed": "Operation is not allowed",
  "auth/weak-password": "Password is weak",
  "auth/popup-closed-by-user":
    "Login operation cancelled by the user - the popup was" + " closed by user",
  "auth/invalid-phone-number": "Phone number is invalid",
  "auth/missing-phone-number": "Phone number is required",
  "auth/phone-number-already-exists": "Phone number already exists",
  "auth/too-many-requests": "Too many requests",
  "auth/captcha-check-failed": "Captcha check failed",
};

export interface AuthServiceInterface<T> {
  loginWithEmail: (email: string, password: string) => Promise<T>;
  loginWithFacebook: () => Promise<T>;
  loginWithGoogle: () => Promise<T>;
  signInWithPhoneNumber: (
    phoneNumber: string,
    appVerifier: RecaptchaVerifier,
  ) => Promise<ConfirmationResult>;
  signupWithEmail: (email: string, password: string) => Promise<T>;
}

export class AuthService
  extends BaseService
  implements AuthServiceInterface<User>
{
  async loginWithGoogle(): Promise<User> {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");

    const userService = new UserService();
    const auth = getAuth();

    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((creds) => {
          userService
            .getUserByUid(creds.user.uid)
            .then(async (user) => {
              resolve(user);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error({ apiErr: error }, "Error logging in with Google");
          reject(error);
        });
    });
  }

  async loginWithFacebook(): Promise<User> {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");

    const auth = getAuth();

    const userService = new UserService();

    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((creds) => {
          userService
            .getUserByUid(creds.user.uid)
            .then(async (user) => {
              resolve(user);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error({ apiErr: error }, "Error logging in with Facebook");
          reject(error);
        });
    });
  }

  async loginWithEmail(email: string, password: string): Promise<User> {
    const auth = getAuth();

    const userService = new UserService();

    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((creds) => {
          userService
            .getUserByUid(creds.user.uid)
            .then(async (user) => {
              resolve(user);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error({ apiErr: error }, "Error logging in with your email");
          reject(error);
        });
    });
  }

  async signupWithEmail(email: string, password: string): Promise<User> {
    const auth = getAuth();

    const userService = new UserService();

    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((creds) => {
          userService
            .getUserByUid(creds.user.uid)
            .then(async (user) => {
              resolve(user);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error({ apiErr: error }, "Error signing up with your email");
          reject(error);
        });
    });
  }

  resetPassword(email: string): Promise<void> {
    const auth = getAuth();

    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error({ apiErr: error }, "Error resetting your password");
          reject(error);
        });
    });
  }

  signInWithPhoneNumber(
    phoneNumber: string,
    appVerifier: RecaptchaVerifier,
  ): Promise<ConfirmationResult> {
    const auth = getAuth();

    auth.languageCode = "en";

    return new Promise((resolve, reject) => {
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          return resolve(confirmationResult);
        })
        .catch((error) => {
          const errCode = error?.code;

          if (errCode && firebaseAuthLoginErrorMessages[errCode]) {
            error.message = firebaseAuthLoginErrorMessages[errCode];
            reject(error);
            return;
          }

          logger.error(
            { apiErr: error },
            "Error sending verification code to your phone number",
          );
          reject(error);
        });
    });
  }
}
