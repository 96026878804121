import { Router } from "next/router";
import { ComponentType, useEffect } from "react";
import { getCookie } from "cookies-next";
import { AUTH_COOKIE_ID } from "../../config/constants";

const withAuth = <P extends object>(
  WrappedComponent: ComponentType<P>,
  router: Router,
  customWhitelistedUris?: string[],
) => {
  const WithAuth: React.FC<P> = (props) => {
    const { asPath, pathname } = router;

    const whitelistedUris = customWhitelistedUris || [
      "/",
      "/auth/login",
      "/auth/forgot-password",
      "/live",
      "/ready",
      "/terms",
      "/privacy",
    ];

    useEffect(() => {
      if (!whitelistedUris.includes(pathname)) {
        const idToken = getCookie(AUTH_COOKIE_ID);

        if (!idToken) {
          const nextUrl = `/auth/login?next=${asPath}`;
          router.push(nextUrl);
        }
      }
    }, [pathname]);

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

export default withAuth;
