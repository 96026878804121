import { useMutation, useQuery } from "react-query";
import { MediaItem, UpdateMediaDto } from "../models/media.model";
import { MediaService } from "../services/media.service";
import { QueryOpts } from "../models/base.model";

export const useUploadFileForStore = (storeId: string) =>
  useMutation<MediaItem, Error, { file: File; is_public?: boolean }>(
    `uploadFileForStore`,
    ({ file, is_public }) => {
      const mediaService = new MediaService();
      return mediaService.uploadFileForStore({
        storeId,
        file,
        is_public,
      });
    },
  );

export const useDeleteMediaMutation = (storeId: string) =>
  useMutation<MediaItem, Error, { mediaId: string }>(
    "deleteMediaMutation",
    ({ mediaId }) => {
      const mediaService = new MediaService();
      return mediaService.deleteMedia(storeId, mediaId);
    },
  );

export const useDeleteMedia = (storeId: string) =>
  useMutation<MediaItem, Error, string>(`deleteMedia`, (mediaId: string) => {
    const mediaService = new MediaService();
    return mediaService.deleteMedia(storeId, mediaId);
  });

export const useUpdateMedia = (storeId: string) =>
  useMutation<MediaItem, Error, { mediaId: string; data: UpdateMediaDto }>(
    `updateMedia`,
    ({ mediaId, data }) => {
      const mediaService = new MediaService();
      return mediaService.updateMedia(storeId, mediaId, data);
    },
  );

export const useGetMediaForStore = (storeId: string, queryOpts: QueryOpts) =>
  useQuery(
    `getMediaForStore_${storeId}`,
    () => {
      const mediaService = new MediaService();
      return mediaService.getMediaForStore(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );
