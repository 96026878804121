import { useMutation, useQuery } from "react-query";
import {
  Account,
  AccountListResponse,
  AllAccountsResponse,
  CreateAccountDto,
  UpdateAccountDto,
} from "./account.model";
import { AccountService } from "./account.service";
import { QueryOpts } from "../../../../models/base.model";

export const useCreateAccountMutation = (storeId: string) =>
  useMutation<Account, Error, CreateAccountDto>((payload) => {
    const accountService = new AccountService();
    return accountService.createAccount(storeId, payload);
  });

export const useUpdateAccountMutation = (storeId: string, accountId: string) =>
  useMutation<Account, Error, UpdateAccountDto>((payload) => {
    const accountService = new AccountService();
    return accountService.updateAccount(storeId, accountId, payload);
  });

export const useGetStoreAccounts = (storeId: string, queryOpts: QueryOpts) =>
  useQuery<AccountListResponse, Error>(
    `getStoreAccounts_${storeId}`,
    () => {
      const accountService = new AccountService();
      return accountService.getStoreAccounts(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetAllStoreAccounts = (storeId: string) =>
  useQuery<AllAccountsResponse, Error>(
    `getAllStoreAccounts_${storeId}`,
    () => {
      const accountService = new AccountService();
      return accountService.getAllStoreAccounts(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetAccount = (storeId: string, accountId: string) =>
  useQuery<Account, Error>(
    `getAccount_${storeId}_${accountId}`,
    () => {
      const accountService = new AccountService();
      return accountService.getAccount(storeId, accountId);
    },
    {
      enabled: Boolean(storeId && accountId),
    },
  );
