import { BaseModel } from "../../../../models/base.model";
import {
  ProductCategory,
  ShortProductCategory,
} from "../../../../models/productCategory.model";
import { Material } from "../../../../models/material.model";
import { PublicMediaItem } from "../../../../models/media.model";
import { Brand } from "../brand/brand.model";

export * from "../../../ecommerce/product/product.ecommerce.model";

export interface BaseProduct extends BaseModel {
  id: string;
  name: string;
  price: string;
  rating: number;
  description: string;
  headline_image?: PublicMediaItem;
  enabled: boolean;

  slug: string;
  sku: string;
  raw_price: number; // Price float value without the currency
  media: PublicMediaItem[];
  keywords: string[];
  importance?: ProductImportance;
  notes?: string;

  on_demand?: boolean;
}

export interface Product extends BaseProduct {
  low_stock_threshold: string;
  overstock_threshold: string;
  materials: Material[];
  available_stock: number;
  category: ProductCategory;
  categories: ProductCategory[];
  unit?: string;
  brand?: Brand;
}

export interface ShortProduct {
  id: string;
  currency: string;
  name: string;
  slug: string;
  available_stock: number;
  unit?: string;
  price: string;
  raw_price: number; // Price float value without the currency
  category: ShortProductCategory;
  category_path: string;
  headline_image?: PublicMediaItem;
  enabled: boolean;
}

// Inventory list item
export interface InventoryListItem {
  id: string;
  product_type: string;
  currency: string;
  name: string;
  slug: string;
  available_stock: string;
  unit?: string;
  price: string;
  raw_price: number; // Price float value without the currency
  headline_image?: PublicMediaItem;
  enabled: boolean;
  overstock_threshold?: string;
  on_demand?: boolean;
  low_stock_threshold?: string;
}

export type AllProduct = Omit<ShortProduct, "category" | "headline_image"> & {
  raw_price: number;
};

export interface CreateProductDto {
  name: string;
  sku?: string;
  unit?: string;

  description: string;
  category_id: number;
  store_id: string;
  price: string;
  available_stock: number;

  variable_price?: boolean;
}

export type BaseUpdateProductDto = {
  slug: string;
  name?: string;
  unit?: string;
  notes?: string;
  description?: string;
  category_id?: string;
  brand_id?: string;
  price?: string;
  variable_price?: boolean;
  //

  headline_image_id?: string;
  media_ids?: string[];
  low_stock_threshold?: string;
  overstock_threshold?: string;
  updated_slug?: string;

  on_demand?: boolean;
  keywords?: string[];
  importance?: ProductImportance;
};

export type UpdateProductDto = Partial<BaseUpdateProductDto> & {
  slug: string;
  keywords?: string[];
  materials?: string[];
};

export enum ProductStockStatus {
  IN_STOCK = "in_stock",
  OUT_OF_STOCK = "out_of_stock",
  LOW_STOCK = "low_stock",
  OVERSTOCK = "overstock",
}

export interface ProductFilter {
  stock_status: ProductStockStatus;
  limit?: number;
  query?: string;
  page?: number;
}

export enum SupportedFileFormats {
  CSV = "csv",
  Excel = "excel",
}

export type DownloadInventoryFilter = Partial<ProductFilter> & {
  file_format?: SupportedFileFormats;
};

export interface ProductStatItem {
  count: number;
  total: number;
}

export interface ProductStats {
  all_time: ProductStatItem;
  today: ProductStatItem;
  current_month: ProductStatItem;
  last_month: ProductStatItem;
}

export type BaseBulkStockUpdateItem = {
  id: string;
  quantity: number;
  note?: string;
};

export type BulkStockUpdateItemDtoWithReason = BaseBulkStockUpdateItem & {
  reason_id: string;
};

export type BulkStockUpdateItemDtoWithProductionLog =
  BaseBulkStockUpdateItem & {
    production_log_id?: string;
  };

export type BulkStockUpdateItemDto =
  | BulkStockUpdateItemDtoWithProductionLog
  | BulkStockUpdateItemDtoWithReason;

export type BulkStockUpdateDto = {
  products: BulkStockUpdateItemDto[];
};

export type CheckoutProduct = {
  id: string;
  name: string;
  price: string;
  product_type: string;
  available_stock: number;
  headline_image: string;
  variable_price: boolean;
  on_demand: boolean;
};

export type ProductSearchItem = Omit<Product, "headline_image"> & {
  currency: string;
  slug: string;
  description: string;
};

export enum ProductImportance {
  Low = "low",
  Medium = "medium",
  High = "high",
}
