import { PublicMediaItem } from "../../../models/media.model";

export enum EleoTimePeriod {
  Today = "today",
  Yesterday = "yesterday",
  Last7Days = "last_7_days",
  Last30Days = "last_30_days",
  ThisMonth = "this_month",
  LastMonth = "last_month",
  ThisYear = "this_year",
  LastYear = "last_year",
  AllTime = "all_time",
  Range = "range_",
}

export interface ProductSalesPerformanceItem {
  id: number;
  slug: string;
  name: string;
  product_type: string;
  headline_image: PublicMediaItem;
  total_amount: number;
  total_orders: number;
}

export interface CategorySalesPerformanceItem {
  category_id: number;
  slug: string;
  name: string;
  total_amount: number;
  order_count: number;
  units_sold: number;
}

export interface SalesPeriod {
  total_amount: number;
  total_orders: number;
  top_products: ProductSalesPerformanceItem[];
  period_title: string;
  top_categories: CategorySalesPerformanceItem[];
}

export interface StoreSalesPerformance {
  current_period: SalesPeriod;
  previous_period?: SalesPeriod;
}

export interface StoreInventoryStatProduct {
  id: number;
  slug: string;
  name: string;
  product_type: string;
  price: number;
  product_value: number;
}

export type StoreInventoryStats = {
  inventory_value: number;
  average_price: number;
  in_stock_count: number;

  num_products: number;
  num_bundles: number;

  out_of_stock_products: number;
  out_of_stock_bundles: number;

  valuable_products: StoreInventoryStatProduct[];

  recent_products: StoreInventoryStatProduct[];
};

export type OrderRelatedStatItem = {
  item_id: string;
  name: string;
  count: number;
};

export type StoreOrderRelatedStats = {
  by_payment_methods: OrderRelatedStatItem[];
  by_outlets: OrderRelatedStatItem[];
  by_fulfillment_channels: OrderRelatedStatItem[];
  by_created_by: OrderRelatedStatItem[];
};

export type SupplierStats = {
  inbound_stock: {
    total_pending: number;
    total_amount: number;
  };
  period_title: string;
};
