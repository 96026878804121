import { BaseService } from "./base.service";
import { ActivityEntities, ActivityList } from "../models/activity.model";
import { QueryOpts } from "../models/base.model";

export class ActivityService extends BaseService {
  getActivities(storeId: string, queryOpts: QueryOpts): Promise<ActivityList> {
    const reqUrl = `activity/${storeId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts.filters,
    });
    return this.makeRequest<ActivityList>(req, reqUrl);
  }

  getActivitiesForEntity(
    storeId: string,
    entity: ActivityEntities,
    entityId: string,
    queryOpts: QueryOpts,
  ): Promise<ActivityList> {
    const reqUrl = `activity/${storeId}/${entity}/${entityId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts.filters,
    });
    return this.makeRequest<ActivityList>(req, reqUrl);
  }
}
