import { BaseService } from "./base.service";
import { CreateOutletDto, Outlet } from "../models/outlet.model";
import { PaginatedResponse, QueryOpts } from "../models/base.model";

export class OutletService extends BaseService {
  getOutlet(storeId: string, outletId: string): Promise<Outlet> {
    const reqUrl = `/outlets/${storeId}/${outletId}/`;
    const req = this.apiClient.get(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  getOutletsForStore(
    storeId: string,
    queryOpts?: QueryOpts,
  ): Promise<PaginatedResponse<Outlet>> {
    const reqUrl = `/outlets/${storeId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest(req, reqUrl);
  }

  createOutletForStore(
    storeId: string,
    data: CreateOutletDto,
  ): Promise<Outlet> {
    const reqUrl = `/outlets/${storeId}/`;
    const req = this.apiClient.post(reqUrl, data);

    return this.makeRequest(req, reqUrl);
  }
}
