import { User } from "../modules/suite/user/user.model";
import { ShortStore } from "./store.model";
import { ShortProduct } from "../modules/suite/inventory/product/product.model";
import { ShortOrder } from "../modules/suite/order/order.model";
import { PaginatedResponse } from "./base.model";

export type ActionItemTargetModel = ShortStore | ShortProduct | ShortOrder;

export interface ActionItem {
  id: string;
  timestamp: string;
  actor: User;
  action_object?: object;
  verb: string;
  target_model: string;
  target: ActionItemTargetModel;
}

export type ActivityList = PaginatedResponse<ActionItem>;

export enum ActivityEntities {
  STORE = "store",
  PRODUCT = "product",
  BUNDLE_PRODUCT = "bundle_product",
  ORDER = "order",
  CUSTOMER = "customer",
}
