import { BaseService } from "../../../services/base.service";
import {
  EntityEventDetail,
  EntityEventFilteringResponse,
  GetEventsResponse,
} from "./entityEvent.model";
import { ApiClientResponse } from "../../../models/responses";
import { QueryOpts } from "../../../models/base.model";

export class EntityEventService extends BaseService {
  getStoreEvents(
    storeId: string,
    queryParams: QueryOpts,
  ): Promise<GetEventsResponse> {
    const reqUrl = `/entity_events/${storeId}/`;
    const req = this.apiClient.get<ApiClientResponse<GetEventsResponse>>(
      reqUrl,
      {
        params: queryParams.filters,
      },
    );
    return this.makeRequest<GetEventsResponse>(req, reqUrl);
  }

  getEvent(storeId: string, eventId: string): Promise<EntityEventDetail> {
    const reqUrl = `/entity_events/${storeId}/${eventId}/`;
    const req =
      this.apiClient.get<ApiClientResponse<EntityEventDetail>>(reqUrl);

    return this.makeRequest<EntityEventDetail>(req, reqUrl);
  }

  getStoreEventFilters(
    storeId: string,
    queryParams: QueryOpts,
  ): Promise<EntityEventFilteringResponse> {
    const reqUrl = `/entity_events/${storeId}/filtering/params/`;
    const req = this.apiClient.get<
      ApiClientResponse<EntityEventFilteringResponse>
    >(reqUrl, {
      params: queryParams.filters,
    });

    return this.makeRequest<EntityEventFilteringResponse>(req, reqUrl);
  }
}
