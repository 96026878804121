import { BaseService } from "../../../services/base.service";
import {
  CancelOrderDto,
  CreatePaymentDto,
  CreateStoreOrderDto,
  Order,
  OrderEventListResponse,
  OrderListResponse,
  RetrievedOrderModel,
  SendOrderInvoiceDto,
  ShortOrder,
} from "./order.model";
import { ApiClientResponse } from "../../../models/responses";
import { PaginatedResponse, QueryOpts } from "../../../models/base.model";
import { CheckoutProduct } from "../inventory/product/product.model";

export class OrderService extends BaseService {
  createStoreOrder(orderData: CreateStoreOrderDto): Promise<Order> {
    const reqUrl = `orders/new/`;
    const req = this.apiClient.post<ApiClientResponse<Order>>(
      reqUrl,
      orderData,
    );

    return this.makeRequest<Order>(req, reqUrl);
  }

  placeStoreOrder(
    storeId: string,
    orderData: CreateStoreOrderDto,
  ): Promise<Order> {
    const reqUrl = `orders/${storeId}/place-order/`;
    const req = this.apiClient.post<ApiClientResponse<Order>>(
      reqUrl,
      orderData,
    );

    return this.makeRequest<Order>(req, reqUrl);
  }

  getStoreOrders(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<OrderListResponse> {
    const reqUrl = `/stores/${storeId}/orders/`;
    const req = this.apiClient.get<ApiClientResponse<OrderListResponse>>(
      reqUrl,
      {
        params: queryOpts?.filters,
      },
    );

    return this.makeRequest<OrderListResponse>(req, reqUrl);
  }

  getStoreOrder(
    storeId: string,
    orderId: string,
  ): Promise<RetrievedOrderModel> {
    const reqUrl = `/orders/${storeId}/order/${orderId}/`;
    const req =
      this.apiClient.get<ApiClientResponse<RetrievedOrderModel>>(reqUrl);

    return this.makeRequest<RetrievedOrderModel>(req, reqUrl);
  }

  getOrderEvents(
    storeId: string,
    orderId: string,
    queryOpts: QueryOpts,
  ): Promise<OrderEventListResponse> {
    const reqUrl = `/orders/${storeId}/order/${orderId}/events/`;
    const req = this.apiClient.get<ApiClientResponse<OrderEventListResponse>>(
      reqUrl,
      {
        params: queryOpts?.filters,
      },
    );

    return this.makeRequest<OrderEventListResponse>(req, reqUrl);
  }

  cancelStoreOrder(
    storeId: string,
    orderId: string,
    cancelOrderDto: CancelOrderDto,
  ): Promise<RetrievedOrderModel> {
    const reqUrl = `/orders/${storeId}/${orderId}/actions/cancel/`;
    const req = this.apiClient.post<ApiClientResponse<RetrievedOrderModel>>(
      reqUrl,
      cancelOrderDto,
    );

    return this.makeRequest<RetrievedOrderModel>(req, reqUrl);
  }

  sendOrderInvoice(
    storeId: string,
    orderId: string,
    payload: SendOrderInvoiceDto,
  ): Promise<void> {
    const reqUrl = `/orders/${storeId}/${orderId}/actions/send_invoice/`;
    const req = this.apiClient.post<ApiClientResponse<void>>(reqUrl, payload);

    return this.makeRequest<void>(req, reqUrl);
  }

  createOrderPayment(
    storeId: string,
    orderId: string,
    paymentData: CreatePaymentDto,
  ): Promise<RetrievedOrderModel> {
    const reqUrl = `/orders/${storeId}/${orderId}/actions/receive_payment/`;
    const req = this.apiClient.post<ApiClientResponse<RetrievedOrderModel>>(
      reqUrl,
      paymentData,
    );

    return this.makeRequest<RetrievedOrderModel>(req, reqUrl);
  }

  getOrdersForProduct(
    productId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<ShortOrder>> {
    const reqUrl = `/orders/products/${productId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest<PaginatedResponse<ShortOrder>>(req, reqUrl);
  }

  getOrdersForBundle(
    bundleId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<ShortOrder>> {
    const reqUrl = `/orders/bundles/${bundleId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest<PaginatedResponse<ShortOrder>>(req, reqUrl);
  }

  getCheckoutProductsForStore(storeId: string): Promise<CheckoutProduct[]> {
    const reqUrl = `/orders/${storeId}/checkout-products/`;
    const req = this.apiClient.get(reqUrl);

    return this.makeRequest<CheckoutProduct[]>(req, reqUrl);
  }
}
