import { BaseService } from "../../../../services/base.service";
import { ApiClientResponse } from "../../../../models/responses";
import {
  AllProduct,
  BulkStockUpdateDto,
  CreateProductDto,
  DownloadInventoryFilter,
  InventoryListItem,
  Product,
  ProductFilter,
  ProductStats,
  UpdateProductDto,
} from "./product.model";
import omit from "lodash/omit";
import { PaginatedResponse, QueryOpts } from "../../../../models/base.model";
import { ShortProductCategory } from "../../../../models/productCategory.model";

export type ProductListResponse = PaginatedResponse<InventoryListItem> & {
  categories: ShortProductCategory[];
};

export class ProductService extends BaseService {
  namespace = "products";

  createProduct(product: CreateProductDto): Promise<Product> {
    const reqUrl = "/products/";
    const req = this.apiClient.post<ApiClientResponse<Product>>(
      reqUrl,
      product,
    );

    return this.makeRequest<Product>(req, reqUrl);
  }

  getStoreInventory(
    storeId: string,
    filters: ProductFilter,
  ): Promise<ProductListResponse> {
    const reqUrl = `/inventory/store/${storeId}/`;

    const req = this.apiClient.get<ApiClientResponse<ProductListResponse>>(
      reqUrl,
      {
        params: filters,
      },
    );

    return this.makeRequest<ProductListResponse>(req, reqUrl);
  }

  downloadStoreInventory(
    storeId: string,
    filters: DownloadInventoryFilter,
  ): Promise<void> {
    const reqUrl = `/inventory/${storeId}/download/`;

    const req = this.apiClient.post<ApiClientResponse<void>>(reqUrl, {
      ...(filters || {}),
    });

    return this.makeRequest<void>(req, reqUrl);
  }

  getAllProductsForStore(
    storeSlug: string,
    filters: ProductFilter,
  ): Promise<AllProduct[]> {
    const reqUrl = `/products/store/${storeSlug}/all/`;
    const req = this.apiClient.get<ApiClientResponse<AllProduct[]>>(reqUrl, {
      params: filters,
    });

    return this.makeRequest<AllProduct[]>(req, reqUrl);
  }

  getProduct(slug: string): Promise<Product> {
    const reqUrl = `/products/${slug}/`;
    const req = this.apiClient.get<ApiClientResponse<Product>>(reqUrl);

    return this.makeRequest<Product>(req, reqUrl);
  }

  getProductStats(slug: string, queryOpts: QueryOpts): Promise<ProductStats> {
    const reqUrl = `/products/${slug}/stats/`;
    const req = this.apiClient.get<ApiClientResponse<ProductStats>>(reqUrl, {
      params: queryOpts?.filters || {},
    });

    return this.makeRequest<ProductStats>(req, reqUrl);
  }

  updateProduct(data: UpdateProductDto): Promise<Product> {
    const reqUrl = `/products/${data.slug}/`;
    const req = this.apiClient.patch<ApiClientResponse<Product>>(reqUrl, {
      ...omit(data, ["slug", "updated_slug"]),
      slug: data.updated_slug,
    });

    return this.makeRequest<Product>(req, reqUrl);
  }

  bulkUpdateProductStock(
    storeSlug: string,
    data: BulkStockUpdateDto,
  ): Promise<void> {
    const reqUrl = `/products/store/${storeSlug}/stock/`;
    const req = this.apiClient.post<ApiClientResponse<void>>(reqUrl, data);

    return this.makeRequest(req, reqUrl);
  }

  archive(id: string): Promise<Product> {
    const reqUrl = `/${this.namespace}/${id}/archive/`;
    const req = this.apiClient.post<ApiClientResponse<Product>>(reqUrl);

    return this.makeRequest<Product>(req, reqUrl);
  }

  enable(id: string): Promise<Product> {
    const reqUrl = `/${this.namespace}/${id}/enable/`;
    const req = this.apiClient.post<ApiClientResponse<Product>>(reqUrl);

    return this.makeRequest<Product>(req, reqUrl);
  }
}
