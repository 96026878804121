import { BaseService } from "./base.service";
import { CreatePayrollDto, Payroll } from "../models/payroll.model";
import { PaginatedResponse } from "../models/base.model";

export class PayrollService extends BaseService {
  getPayrollsForStore(storeId: string): Promise<PaginatedResponse<Payroll>> {
    const reqUrl = `/payroll/${storeId}/`;
    const req = this.apiClient.get(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  getPayrollForStore(storeId: string, payrollId: string): Promise<Payroll> {
    const reqUrl = `/payroll/${storeId}/${payrollId}/`;
    const req = this.apiClient.get(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  createPayrollForStore(
    storeId: string,
    data: CreatePayrollDto,
  ): Promise<Payroll> {
    const reqUrl = `/payroll/${storeId}/`;
    const req = this.apiClient.post(reqUrl, data);

    return this.makeRequest(req, reqUrl);
  }

  addTeamMemberToPayroll(
    storeId: string,
    payrollId: string,
    teamMemberId: string,
  ): Promise<Payroll> {
    const reqUrl = `/payroll/${storeId}/${payrollId}/add/`;
    const req = this.apiClient.post(reqUrl, {
      team_member_id: teamMemberId,
    });

    return this.makeRequest(req, reqUrl);
  }

  addAllTeamMembersToPayroll(storeId: string, payrollId: string) {
    const reqUrl = `/payroll/${storeId}/${payrollId}/add/all/`;
    const req = this.apiClient.post(reqUrl, {});

    return this.makeRequest(req, reqUrl);
  }

  resetPayroll(storeId: string, payrollId: string) {
    const reqUrl = `/payroll/${storeId}/${payrollId}/reset/`;
    const req = this.apiClient.post(reqUrl, {});

    return this.makeRequest(req, reqUrl);
  }

  makePaymentForLineItem(
    storeId: string,
    payrollId: string,
    itemId: string,
  ): Promise<Payroll> {
    const reqUrl = `/payroll/${storeId}/${payrollId}/pay/`;
    const req = this.apiClient.post(reqUrl, {
      line_item_id: itemId,
    });

    return this.makeRequest(req, reqUrl);
  }
}
