import { useMutation, useQuery } from "react-query";
import {
  BundleProduct,
  BundleProductListResponse,
  CreateBundleProductDto,
  UpdateBundleProductDto,
} from "./bundleProduct.model";
import { BundleProductService } from "./bundleProduct.service";
import { QueryOpts } from "../../../../models/base.model";
import { ProductStats } from "../product/product.model";

export const useCreateBundleProductMutation = () =>
  useMutation<
    BundleProduct,
    Error,
    { storeSlug: string; data: CreateBundleProductDto }
  >(({ storeSlug, data }) => {
    const bundleProductService = new BundleProductService();
    return bundleProductService.createBundleProduct(storeSlug, data);
  });
export const useUpdateBundleProductMutation = () =>
  useMutation<
    BundleProduct,
    Error,
    { slug: string; data: UpdateBundleProductDto }
  >(({ slug, data }) => {
    const bundleProductService = new BundleProductService();
    return bundleProductService.updateBundleProduct(slug, data);
  });

export const useGetBundleProductsForStoreQuery = (
  storeSlug: string,
  queryOpts: QueryOpts,
) =>
  useQuery<BundleProductListResponse, Error>(
    `getBundleProductsForStore${storeSlug}_${JSON.stringify(queryOpts)}`,
    () => {
      const bundleProductService = new BundleProductService();
      return bundleProductService.getBundleProductsForStore(
        storeSlug,
        queryOpts,
      );
    },

    {
      enabled: queryOpts?.enabled ?? true,
    },
  );

export const useGetBundleProductQuery = (slug: string) =>
  useQuery<BundleProduct, Error>(
    `getBundleProduct_${slug}`,
    () => {
      const bundleProductService = new BundleProductService();

      return bundleProductService.getBundleProduct(slug);
    },
    {
      enabled: Boolean(slug),
    },
  );

export const useGetBundleStatsQuery = (slug: string, queryOpts: QueryOpts) =>
  useQuery<ProductStats, Error>(
    ["getBundleStats", slug, queryOpts],
    () => {
      const bundleProductService = new BundleProductService();

      return bundleProductService.getBundleStats(slug, queryOpts);
    },
    {
      enabled: Boolean(slug),
    },
  );

export const useArchiveBundleMutation = () =>
  useMutation<BundleProduct, Error, string>((id) => {
    const productService = new BundleProductService();
    return productService.archive(id);
  });

export const useEnableBundleMutation = () =>
  useMutation<BundleProduct, Error, string>((id) => {
    const bundleService = new BundleProductService();
    return bundleService.enable(id);
  });
