import { BaseService } from "./base.service";
import { ApiClientResponse } from "../models/responses";
import { Country, GeoState } from "../models/country.model";
import { PaginatedResponse } from "../models/base.model";

export class CountryService extends BaseService {
  getCountries(): Promise<PaginatedResponse<Country>> {
    const reqUrl = "/countries/";
    const req =
      this.apiClient.get<ApiClientResponse<PaginatedResponse<Country>>>(reqUrl);

    return this.makeRequest<PaginatedResponse<Country>>(req, reqUrl);
  }

  getStatesForCountry(countryId: string): Promise<GeoState[]> {
    const reqUrl = `/countries/${countryId}/states/`;
    const req = this.apiClient.get<ApiClientResponse<GeoState[]>>(reqUrl);

    return this.makeRequest<GeoState[]>(req, reqUrl);
  }
}
