import { useQuery } from "react-query";
import { FulfillmentChannel } from "../modules/suite/order/order.model";
import { FulfillmentService } from "../services/fulfillment.service";

export const useGetStoreFulfillmentChannels = (storeId: string) =>
  useQuery<FulfillmentChannel[], Error>(
    `getFulfillmentChannelsForStore${storeId}`,
    () => {
      const fulfillmentService = new FulfillmentService();
      return fulfillmentService.getFulfillmentChannels(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetAllFulfillmentChannels = () =>
  useQuery<FulfillmentChannel[], Error>(`getAllFulfillmentChannelsFor`, () => {
    const fulfillmentService = new FulfillmentService();
    return fulfillmentService.getAllFulfillmentChannels();
  });
