import { BaseModel } from "./base.model";
import { ReadOnlyUser } from "../modules/suite/user/user.model";

export type BasePurchaseHistory = {
  name?: string;
  purchase_date: string;
};

export type PurchaseHistory = BasePurchaseHistory &
  BaseModel & {
    created_by: ReadOnlyUser;
    name: string;
    subtotal: number;
  };

export type BasePurchaseLineItemDto = {
  name: string;
  quantity?: number;
  unit?: string;
  amount: number;
};

export type UnlinkedPurchaseLineItemDto = BasePurchaseLineItemDto;

export enum LinkedPurchaseLineItemDtoType {
  Product = "product",
  Material = "material",
}

export type LinkedPurchaseLineItemDto = BasePurchaseLineItemDto & {
  item_type: LinkedPurchaseLineItemDtoType;
  item_id: string;
};

export type PurchaseLineItemDto =
  | UnlinkedPurchaseLineItemDto
  | LinkedPurchaseLineItemDto;

export type CreatePurchaseHistoryDto = BasePurchaseHistory & {
  purchase_items: PurchaseLineItemDto[];
};

export type UpdatePurchaseHistoryDto = BasePurchaseHistory & {
  purchase_items: PurchaseLineItemDto[];
};
