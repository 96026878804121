import { BaseService } from "./base.service";
import {
  CreatePurchaseHistoryDto,
  PurchaseHistory,
} from "../models/purchases.model";
import { ApiClientResponse } from "../models/responses";
import { PaginatedResponse } from "../models/base.model";

export class PurchaseService extends BaseService {
  createPurchase = async (
    storeId: string,
    data: CreatePurchaseHistoryDto,
  ): Promise<PurchaseHistory> => {
    const reqUrl = `purchases/${storeId}/`;
    const req = this.apiClient.post<ApiClientResponse<PurchaseHistory>>(
      reqUrl,
      data,
    );

    return this.makeRequest<PurchaseHistory>(req, reqUrl);
  };

  getPurchases = async (
    storeId: string,
  ): Promise<PaginatedResponse<PurchaseHistory>> => {
    const reqUrl = `purchases/${storeId}/`;
    const req =
      this.apiClient.get<ApiClientResponse<PaginatedResponse<PurchaseHistory>>>(
        reqUrl,
      );

    return this.makeRequest<PaginatedResponse<PurchaseHistory>>(req, reqUrl);
  };
}
