import { useQuery } from "react-query";
import { CountryService } from "../services/country.service";
import { Country, GeoState } from "../models/country.model";
import { PaginatedResponse } from "../models/base.model";

export const useGetCountries = () =>
  useQuery<PaginatedResponse<Country>, Error>("getCountries", async () => {
    const countryService = new CountryService();

    return countryService.getCountries();
  });

export const useGetStatesForCountry = (countryId: string) =>
  useQuery<GeoState[], Error>(
    `states_${countryId}`,
    async () => {
      const countryService = new CountryService();

      return countryService.getStatesForCountry(countryId);
    },
    {
      enabled: Boolean(countryId),
    },
  );
