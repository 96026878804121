import { useQuery } from "react-query";
import { PublicOrderModel } from "../../models/public/order.public.model";
import { PublicOrderService } from "../../services/public/order.public.service";

export const useGetOrderQuery = (orderId: string) =>
  useQuery<PublicOrderModel, Error>(
    `order_${orderId}`,
    () => {
      const orderService = new PublicOrderService();
      return orderService.getOrder(orderId);
    },
    {
      enabled: Boolean(orderId),
    },
  );
