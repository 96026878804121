import axios from "axios";
import { getAuth } from "firebase/auth";

export type CreateApiClientParams = {
  baseURL?: string;
  allowUnauthenticated?: boolean;
};

export const createApiClient = ({
  baseURL,
  allowUnauthenticated,
}: CreateApiClientParams) => {
  const instance = axios.create({
    baseURL: baseURL || process.env.NEXT_PUBLIC_API_ROOT,

    paramsSerializer: {
      indexes: null,
    },
  });

  instance.interceptors.request.use(async (config) => {
    if (allowUnauthenticated) {
      return config;
    }

    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      throw new axios.Cancel("Operation canceled by the user.");
    }

    if (token) {
      (config as any).headers = {
        ...(config.headers || {}),
        authorization: token.trim(),
      };
    }

    return config;
  });

  return instance;
};
