import { BaseService } from "./base.service";
import {
  MediaItem,
  UpdateMediaDto,
  UploadFileDto,
} from "../models/media.model";
import { PaginatedResponse, QueryOpts } from "../models/base.model";
import { ApiClientResponse } from "../models/responses";

export class MediaService extends BaseService {
  uploadFileForStore(data: UploadFileDto): Promise<MediaItem> {
    const reqUrl = `/media/${data.storeId}/media/upload/`;
    const body = new FormData();

    body.append("file_obj", data.file);
    body.append("is_public", data.is_public ? "true" : "false");

    const req = this.apiClient.post(reqUrl, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return this.makeRequest(req, reqUrl);
  }

  getMediaForStore(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<MediaItem>> {
    const reqUrl = `/media/${storeId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: { ...(queryOpts || {}) },
    });

    return this.makeRequest(req, reqUrl);
  }

  deleteMedia(storeId: string, mediaId: string): Promise<MediaItem> {
    const reqUrl = `/media/${storeId}/${mediaId}/`;
    const req = this.apiClient.delete<ApiClientResponse<MediaItem>>(reqUrl);

    return this.makeRequest<MediaItem>(req, reqUrl);
  }

  updateMedia(
    storeId: string,
    mediaId: string,
    data: UpdateMediaDto,
  ): Promise<MediaItem> {
    const reqUrl = `/media/${storeId}/${mediaId}/`;
    const req = this.apiClient.patch<ApiClientResponse<MediaItem>>(
      reqUrl,
      data,
    );

    return this.makeRequest<MediaItem>(req, reqUrl);
  }
}
