import { ReadOnlyUser } from "../../user/user.model";
import { PaginatedResponse } from "../../../../models/base.model";
import { NestedAccountItem } from "../accounts/account.model";
import { AllSupplierItem } from "../../../../models/supplier.model";

export enum TransactionType {
  Credit = "credit",
  Debit = "debit",
}

export type CreateTransactionLineItemDto = {
  title: string;
  unit_price: number;
  description?: string;
  quantity?: number;
};

export type CreateTransactionDto = {
  amount: number;
  title: string;
  description?: string;
  type: TransactionType;
  account_id: string;
  line_items?: CreateTransactionLineItemDto[];
  supplier_id?: string;
};

export type UpdateTransactionDto = {
  title: string;
  description: string;
};

export type BaseTransaction = {
  id: string;
  status: string;
  title: string;
  type: TransactionType;
  amount: number;
  description: string;
  created_at: string;
  updated_at: string;
  created_by: ReadOnlyUser;
  account: {
    id: string;
    name: string;
    currency: string;
  };
};

export type TransactionLineItemListItem = {
  id: string;
  title: string;
  unit_price: number;
  description: string;
  quantity: number;
  supplier?: AllSupplierItem;
  subtotal: number;
};

export type Transaction = BaseTransaction & {
  account: NestedAccountItem;
  human_friendly_amount: number;
  line_items: TransactionLineItemListItem[];
  supplier?: AllSupplierItem;
};

export type TransactionListItem = BaseTransaction;

export type TransactionListResponse = PaginatedResponse<TransactionListItem>;

export enum TransactionStatus {
  Cancelled = "cancelled",
  Completed = "completed",
}

export const transactionStatusRules = {
  success: [TransactionStatus.Completed],
  error: [TransactionStatus.Cancelled],
  pending: [],
  warning: [],
};

export const transactionTypeRules = {
  success: [TransactionType.Credit],
  error: [TransactionType.Debit],
  pending: [],
  warning: [],
};
export type StoreTransactionTitlesResponse = string[];

export type ChangeTransactionAccountDto = {
  account_id: string;
};
