import { useMutation, useQuery } from "react-query";
import {
  ChangeTransactionAccountDto,
  CreateTransactionDto,
  StoreTransactionTitlesResponse,
  Transaction,
  TransactionListResponse,
  UpdateTransactionDto,
} from "./transaction.model";
import { TransactionService } from "./transaction.service";
import { QueryOpts } from "../../../../models/base.model";

export const useCreateTransactionMutation = (storeId: string) =>
  useMutation<Transaction, Error, CreateTransactionDto>((payload) => {
    const transactionService = new TransactionService();
    return transactionService.createTransaction(storeId, payload);
  });

export const useUpdateTransactionMutation = (
  storeId: string,
  transactionId: string,
) =>
  useMutation<Transaction, Error, UpdateTransactionDto>((payload) => {
    const transactionService = new TransactionService();
    return transactionService.updateTransaction(
      storeId,
      transactionId,
      payload,
    );
  });
export const useChangeTransactionAccountMutation = (
  storeId: string,
  transactionId: string,
) =>
  useMutation<Transaction, Error, ChangeTransactionAccountDto>((payload) => {
    const transactionService = new TransactionService();
    return transactionService.chaneTransactionAccount(
      storeId,
      transactionId,
      payload,
    );
  });

export const useGetStoreTransactions = (
  storeId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<TransactionListResponse, Error>(
    `getStoreTransactions_${storeId}_${JSON.stringify(queryOpts)}`,
    () => {
      const transactionService = new TransactionService();
      return transactionService.getStoreTransactions(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetStoreTransactionTitles = (storeId: string) =>
  useQuery<StoreTransactionTitlesResponse, Error>(
    `getStoreTransactions_${storeId}`,
    () => {
      const transactionService = new TransactionService();
      return transactionService.getStoreTransactionTitles(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetTransaction = (storeId: string, transactionId: string) =>
  useQuery<Transaction, Error>(
    `getTransaction_${storeId}_${transactionId}`,
    () => {
      const transactionService = new TransactionService();
      return transactionService.getTransaction(storeId, transactionId);
    },
    {
      enabled: Boolean(storeId && transactionId),
    },
  );
