import { BaseModel, PaginatedResponse } from "../../../models/base.model";
import { ReadOnlyUser } from "../user/user.model";

export type PaymentMethodType = BaseModel & {
  code: string;
  name: string;
  description: string;
  is_active: boolean;
};

export type BaseStorePaymentMethod = {
  is_active: boolean;
  is_instant_confirmation: boolean;
  name: string;
};

export type StorePaymentMethodDto = BaseStorePaymentMethod & {
  id?: string;
  type_id: string;
};

export type StorePaymentMethod = BaseStorePaymentMethod & {
  id: string;
  type: PaymentMethodType;
};

export type PaymentItem = {
  id: string;
  created_at: string;
  created_by: ReadOnlyUser;
  status: string;
  payment_method: StorePaymentMethod;
  amount: string;
  amount_left: string;
  order: string; // Order ID
  customer: {
    id: string;
    display_name: string;
  };
  requires_confirmation: boolean;
};

export enum PaymentStatus {
  Pending = "pending",
  Paid = "paid",
  Failed = "failed",
  Cancelled = "cancelled",
  Refunded = "refunded",
}

export const paymentStatusRules = {
  success: [PaymentStatus.Paid],
  pending: [PaymentStatus.Pending],
  failed: [PaymentStatus.Failed, PaymentStatus.Cancelled],
  warning: [PaymentStatus.Refunded],
  error: [],
};

export type PaymentFilter = {
  status?: string;
  page?: string;
};

export type StorePaymentListResponse = PaginatedResponse<PaymentItem>;

export const STORE_CREDIT_PAYMENT_METHOD_CODE = "store_credit";

export type AddStoreCreditDto = {
  amount: string;
};
