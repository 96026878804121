import { PublicMediaItem } from "../../../models/media.model";

/**
 * Used specifically for product list responses from the customer API
 */
export type CustomerProductListItem = {
  id: string;
  name: string;
  description: string;
  price: string;
  available_stock: number;
  country_code: string;
  currency: string;
  product_type: string;
  slug: string;
  store: {
    id: string;
    name: string;
    slug: string;
  };
  categories: {
    id: string;
    name: string;
    slug: string;
  }[];
  category_names: string[];
  category: {
    id: string;
    name: string;
    slug: string;
  };
  category_path: string;
  headline_image_path: string;
  headline_image?: PublicMediaItem;
  on_demand?: boolean;
};

export type CustomerProductSearchStats = {
  price: {
    min: number;
    max: number;
  };
};

export interface FacetKeyValuePair {
  key: string;
  value: number;
}

export enum FacetMembers {
  CURRENCY = "currency",
  CATEGORY_NAMES = "category_names",
  PRODUCT_TYPE = "product_type",
}

export type CustomerProductFacet = {
  [key in FacetMembers]: FacetKeyValuePair[];
};

export type CustomerProductSearchResponse = {
  products: CustomerProductListItem[];
  stats: CustomerProductSearchStats;
  pagination: {
    total: number;
    pages: number;
    current_page: number;
  };
  facets: CustomerProductFacet;
};

export interface CustomerApiProductSearchParams {
  query: string;
  page?: number;
  store?: string; // Store slug
  facets?: string[];
}
