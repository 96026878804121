import React, { PropsWithChildren, useState } from "react";
import dynamic from "next/dynamic";

const AlertModal = dynamic(() =>
  import("./AlertModal").then((mod) => mod.AlertModal),
);

const BaseModal = dynamic(() =>
  import("./BaseModal").then((mod) => mod.BaseModal),
);

export interface ModalConfigProps {
  title: string;
  body: React.ReactNode;
  isAlert?: boolean;
  onConfirm?: () => void;
  onDecline?: () => void;
}

export interface IModal {
  closeModal: () => void;
  openModal: (c: ModalConfigProps) => void;
  modalContent: ModalConfigProps | null;
}

const ModalContext = React.createContext<IModal>({
  closeModal: () => null,
  openModal: () => null,
  modalContent: null,
});

export const useModal = () => React.useContext(ModalContext);

export const ModalProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [show, setShow] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfigProps | null>(null);

  return (
    <ModalContext.Provider
      value={{
        closeModal: () => {
          setShow(false);
          setModalConfig(null);
        },
        openModal: (c) => {
          setModalConfig(c);
          setShow(true);
        },
        modalContent: modalConfig,
      }}
    >
      {modalConfig?.isAlert ? (
        <AlertModal config={modalConfig} isOpen={show} />
      ) : (
        <BaseModal config={modalConfig} isOpen={show} />
      )}

      {children}
    </ModalContext.Provider>
  );
};
