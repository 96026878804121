import { useMutation, useQuery } from "react-query";
import { SupplierService } from "../services/supplier.service";
import {
  AllSuppliersResponse,
  CreateSupplierDto,
  Supplier,
  UpdateSupplierDto,
} from "../models/supplier.model";
import { QueryOpts } from "../models/base.model";

export const useCreateSupplier = () =>
  useMutation<
    Supplier,
    Error,
    {
      storeId: string;
      data: CreateSupplierDto;
    }
  >((data) => {
    const supplierService = new SupplierService();
    return supplierService.createSupplier(data.storeId, data.data);
  });

export const useUpdateSupplier = () =>
  useMutation<
    Supplier,
    Error,
    {
      storeId: string;
      supplierId: string;
      data: UpdateSupplierDto;
    }
  >((data) => {
    const supplierService = new SupplierService();
    return supplierService.updateStoreSupplier(
      data.storeId,
      data.supplierId,
      data.data,
    );
  });

export const useGetSuppliers = (storeId: string, queryOpts: QueryOpts) =>
  useQuery(
    `getStoreSuppliers_${storeId}_${JSON.stringify(queryOpts)}`,
    () => {
      const supplierService = new SupplierService();
      return supplierService.getStoreSuppliers(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetAllStoreSuppliers = (storeId: string) =>
  useQuery<AllSuppliersResponse, Error>(
    `getStoreSuppliers_${storeId}_all`,
    () => {
      const supplierService = new SupplierService();
      return supplierService.getAllStoreSuppliers(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetSupplier = (storeId: string, supplierId: string) =>
  useQuery(
    `getStoreSupplier_${storeId}_${supplierId}`,
    () => {
      const supplierService = new SupplierService();
      return supplierService.getStoreSupplier(storeId, supplierId);
    },
    {
      enabled: Boolean(storeId && supplierId),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
