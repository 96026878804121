import { BaseService } from "../../../../services/base.service";
import { QueryOpts } from "../../../../models/base.model";
import {
  BundleProduct,
  BundleProductListResponse,
  CreateBundleProductDto,
  UpdateBundleProductDto,
} from "./bundleProduct.model";
import { ProductStats } from "../product/product.model";
import { ApiClientResponse } from "../../../../models/responses";

export class BundleProductService extends BaseService {
  namespace = "bundles";

  getBundleProductsForStore(
    storeSlug: string,
    queryOpts: QueryOpts,
  ): Promise<BundleProductListResponse> {
    if (!storeSlug) {
      throw new Error("The store slug is required");
    }

    const reqUrl = `/bundles/store/${storeSlug}/`;

    const req = this.apiClient.get(reqUrl, {
      params: queryOpts.filters,
    });

    return this.makeRequest(req, reqUrl);
  }

  getBundleProduct(bundleProductSlug: string): Promise<BundleProduct> {
    if (!bundleProductSlug) {
      throw new Error("The bundle product slug is required");
    }

    const reqUrl = `/bundles/${bundleProductSlug}/`;

    const req = this.apiClient.get(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  createBundleProduct(
    storeSlug: string,
    bundleProduct: CreateBundleProductDto,
  ): Promise<BundleProduct> {
    const reqUrl = `/bundles/store/${storeSlug}/`;
    const req = this.apiClient.post(reqUrl, bundleProduct);

    return this.makeRequest(req, reqUrl);
  }

  updateBundleProduct(
    bundleSlug: string,
    data: UpdateBundleProductDto,
  ): Promise<BundleProduct> {
    const reqUrl = `/bundles/${bundleSlug}/`;
    const req = this.apiClient.patch(reqUrl, {
      ...data,
      slug: data.updated_slug,
    });

    return this.makeRequest(req, reqUrl);
  }

  getBundleStats(slug: string, queryOpts: QueryOpts): Promise<ProductStats> {
    const reqUrl = `/bundles/${slug}/stats/`;
    const req = this.apiClient.get<ApiClientResponse<ProductStats>>(reqUrl, {
      params: queryOpts.filters || {},
    });

    return this.makeRequest<ProductStats>(req, reqUrl);
  }

  archive(id: string): Promise<BundleProduct> {
    const reqUrl = `/${this.namespace}/${id}/archive/`;
    const req = this.apiClient.post<ApiClientResponse<BundleProduct>>(reqUrl);

    return this.makeRequest<BundleProduct>(req, reqUrl);
  }

  enable(id: string): Promise<BundleProduct> {
    const reqUrl = `/${this.namespace}/${id}/enable/`;
    const req = this.apiClient.post<ApiClientResponse<BundleProduct>>(reqUrl);

    return this.makeRequest<BundleProduct>(req, reqUrl);
  }
}
