import { AxiosInstance, AxiosResponse } from "axios";
import { createApiClient, CreateApiClientParams } from "./apiClient";
import { ApiClientResponse } from "../models/responses";
import { logger } from "../libs/logger";

export interface APIError {
  message: string;
  code: string;
  errors: any[];
  request_id: string;
}

export class ServiceBootstrap {}

export class BaseService {
  public readonly apiClient: AxiosInstance;
  public namespace: string;

  constructor(apiClientOptions?: CreateApiClientParams, namespace?: string) {
    this.apiClient = createApiClient(apiClientOptions || {});
    this.namespace = namespace || "";
  }

  public makeRequestWithFetch<T>(
    request: Promise<Response>,
    reqUrl: string,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      request
        .then(this.fetchResponseHandler<T>(resolve, reject))
        .catch(this.defaultErrorHandler(reject, reqUrl));
    });
  }

  prepFetchRequest(
    reqUrl: string,
    options: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  ): Promise<Response> {
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_API_URL}${reqUrl}`;

    logger.debug(`Making request to ${url}`);
    return fetch(url, options);
  }

  fetchResponseHandler<T>(
    resolve: (data: T) => void,
    reject: (errors: any) => void,
  ) {
    return (res: Response) => {
      if (!res.ok) {
        return reject(res.statusText);
      }

      const data = res.json();

      return resolve(data as T);
    };
  }

  public makeRequest<T>(
    request: Promise<AxiosResponse<ApiClientResponse<T>>>,
    reqUrl: string,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      request
        .then(this.defaultResponseHandler<T>(resolve, reject))
        .catch(this.defaultErrorHandler(reject, reqUrl));
    });
  }

  defaultResponseHandler<T>(
    resolve: (data: T) => void,
    reject: (errors: any) => void,
  ) {
    return (res: AxiosResponse<ApiClientResponse<T>>) => {
      const { data } = res;

      if (data.errors) {
        return reject(data.errors);
      }

      return resolve(data);
    };
  }

  defaultErrorHandler(reject: (errors: any) => void, reqUrl: string) {
    return (err: any) => {
      logger.warn({ apiErr: err }, `Error in ${reqUrl}`);
      const errData = err.response?.data as APIError;

      if (!errData) {
        return reject(err);
      }

      return reject({
        message: errData.message,
        errors: errData.errors || [],
      });
    };
  }
}
