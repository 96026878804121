import { useMutation, useQuery } from "react-query";
import { StoreService } from "./store.service";
import {
  CreateStoreDto,
  CustomerOrderStats,
  MonthlyOrderStats,
  ProductPerfStats,
  Store,
  StoreDashboardConfig,
  StoreSetting,
  UpdateStoreSettingsDto,
} from "../../../models/store.model";
import { QueryOpts } from "../../../models/base.model";

export const useCreateStoreMutation = () =>
  useMutation<Store, Error, CreateStoreDto>((formData) => {
    const storeService = new StoreService();
    return storeService.createStore(formData);
  });

export const useGetProductPerfStats = (storeId: string, queryOpts: QueryOpts) =>
  useQuery<ProductPerfStats, Error>(
    `productPerfStats_${storeId}`,
    () => {
      const storeService = new StoreService();
      return storeService.getStoreProductPerfStats(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetMonthlyOrderStats = (
  storeId: string,
  year: string,
  month: string,
) =>
  useQuery<MonthlyOrderStats, Error>(
    `monthlyOrderStats_${storeId}_${year}_${month}`,
    () => {
      const storeService = new StoreService();
      return storeService.getMonthlyOrderStats(storeId, year, month);
    },
    {
      enabled: Boolean(storeId && year && month),
    },
  );
export const useGetCustomerOrderStats = (
  storeId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<CustomerOrderStats, Error>(
    `customerOrderStats_${storeId}_`,
    () => {
      const storeService = new StoreService();
      return storeService.getCustomerOrderStats(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetStoreSetting = (storeId: string, queryOpts?: QueryOpts) =>
  useQuery<StoreSetting, Error>(
    `storeSettings_${storeId}`,
    () => {
      const storeService = new StoreService();
      return storeService.getStoreSettings(storeId);
    },
    {
      enabled: Boolean(storeId),
      refetchOnMount: queryOpts?.noRefetch ?? undefined,
      refetchOnReconnect: queryOpts?.noRefetch ?? undefined,
      refetchOnWindowFocus: queryOpts?.noRefetch ?? undefined,
    },
  );
export const useGetStoreDashboardConfig = (storeId: string) =>
  useQuery<StoreDashboardConfig, Error>(
    `storeDashboardConfig_${storeId}`,
    () => {
      const storeService = new StoreService();
      return storeService.getStoreDashboardConfig(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useUpdateStoreSettingMutation = () =>
  useMutation<
    StoreSetting,
    Error,
    { storeId: string; data: UpdateStoreSettingsDto }
  >(({ storeId, data }) => {
    const storeService = new StoreService();
    return storeService.updateStoreSettings(storeId, data);
  });
