const normalizeCss = {
  html: {
    lineHeight: 1.15,
    "-webkit-text-size-adjust": "100%",
  },

  body: {
    margin: 0,
  },

  main: {
    display: "block",
  },

  h1: {
    fontSize: "2em",
    margin: "0.67em 0",
  },

  hr: {
    boxSizing: "content-box",
    height: 0,
    overflow: "visible",
  },

  a: {
    backgroundColor: "transparent",
  },

  b: {
    fontWeight: "bolder",
  },

  strong: {
    fontWeight: "bolder",
  },

  img: {
    borderStyle: "none",
  },

  button: {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
    overflow: "visible",
    textTransform: "none",
    "-webkit-appearance": "button",
  },
  input: {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
    overflow: "visible",
  },
  optgroup: {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
  },
  select: {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
    textTransform: "none",
  },
  textarea: {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
  },
};

export default normalizeCss;
