import { BaseService } from "../../../../services/base.service";
import {
  CreateStockHistoryReasonDto,
  StockHistoryFilter,
  StockHistoryListResponse,
  StockHistoryReason,
} from "./stockHistory.model";
import { ApiClientResponse } from "../../../../models/responses";

export class StockHistoryService extends BaseService {
  namespace = "stock_history";

  getStockHistoryForStore(
    storeId: string,
    filters: StockHistoryFilter,
  ): Promise<StockHistoryListResponse> {
    const reqUrl = `/stock_history/${storeId}/`;
    const req = this.apiClient.get<ApiClientResponse<StockHistoryListResponse>>(
      reqUrl,
      {
        params: filters,
      },
    );

    return this.makeRequest<StockHistoryListResponse>(req, reqUrl);
  }

  getStockHistoryReasonsForStore(
    storeId: string,
  ): Promise<StockHistoryReason[]> {
    const reqUrl = `/stock_history/${storeId}/reasons/`;
    const req =
      this.apiClient.get<ApiClientResponse<StockHistoryReason[]>>(reqUrl);

    return this.makeRequest<StockHistoryReason[]>(req, reqUrl);
  }

  createStockHistoryReasonForStore(
    storeId: string,
    payload: CreateStockHistoryReasonDto,
  ): Promise<StockHistoryReason> {
    const reqUrl = `/stock_history/${storeId}/reasons/`;
    const req = this.apiClient.post<ApiClientResponse<StockHistoryReason>>(
      reqUrl,
      payload,
    );

    return this.makeRequest<StockHistoryReason>(req, reqUrl);
  }

  updateStockHistoryReasonForStore(
    storeId: string,
    reasonId: string,
    payload: CreateStockHistoryReasonDto,
  ): Promise<StockHistoryReason> {
    const reqUrl = `/stock_history/${storeId}/reasons/${reasonId}/`;
    const req = this.apiClient.patch<ApiClientResponse<StockHistoryReason>>(
      reqUrl,
      payload,
    );

    return this.makeRequest<StockHistoryReason>(req, reqUrl);
  }
}
