import { BaseModel, PaginatedResponse } from "../../../models/base.model";
import { ReadOnlyUser, User } from "../user/user.model";
import { Product } from "../inventory/product/product.model";
import {
  FulfillmentDto,
  FulfillmentHistory,
} from "../../../models/fulfillment.model";
import { PaymentItem, StorePaymentMethod } from "../payments/payment.model";
import { Customer } from "../customer/customer.model";

export interface CartItem extends BaseModel {
  product_id: string;
  quantity: number;
  cart: Cart;
}

export interface Cart extends BaseModel {
  cart_items: CartItem[];
  created_by: ReadOnlyUser;
  user: User;
}

export type CreatedCart = Omit<Cart, "cart_items"> & {
  items: CreatedCartItem[];
};

export interface CreatedCartItem extends BaseModel {
  product: Product;
  product_type: string;
  total_price: number;
  quantity: number;
  subtotal: number;
  total: number;
}

export enum OrderStatus {
  Draft = "draft",
  Pending = "pending",
  AwaitingPayment = "awaiting_payment",
  AwaitingShipment = "awaiting_shipment",
  AwaitingFulfillment = "awaiting_fulfillment",
  AwaitingPickup = "awaiting_pickup",
  Completed = "completed",
  Shipped = "shipped",
  Cancelled = "cancelled",
  Declined = "declined",
  Refunded = "refunded",
  Disputed = "disputed",
  PartiallyRefunded = "partially_refunded",
  ManualVerificationRequired = "manual_verification_required",

  Placed = "placed", // Deprecated
  PaymentDue = "payment_due", // Deprecated
  Paid = "paid",
}

export const orderStatusRules = {
  success: [
    OrderStatus.Completed,
    OrderStatus.Shipped,
    OrderStatus.Refunded,
    OrderStatus.Paid,
  ],
  pending: [OrderStatus.Draft, OrderStatus.Placed, OrderStatus.Pending],
  error: [
    OrderStatus.Cancelled,
    OrderStatus.Declined,
    OrderStatus.PartiallyRefunded,
    OrderStatus.ManualVerificationRequired,
    OrderStatus.Disputed,
  ],
  warning: [
    OrderStatus.PaymentDue,
    OrderStatus.AwaitingPayment,
    OrderStatus.AwaitingShipment,
    OrderStatus.AwaitingFulfillment,
    OrderStatus.AwaitingPickup,
  ],
};

export interface Order extends BaseModel {
  cart: Cart;
  // fulfillment_channel: FulfillmentChannel;
  customer: Customer | null;
  created_by: ReadOnlyUser;
  status: string;
  total_price: string;
  total_price_currency: string;
}

export interface ShortOrder extends BaseModel {
  customer: Customer;
  created_by: ReadOnlyUser;
  status: string;
  total_price: string;
  total_price_currency: string;
  discount_amount: string;
}

export interface FulfillmentChannel extends BaseModel {
  code: string;
  name: string;
  has_charges: boolean;
  supports_time_slots?: boolean;
}

export type RetrievedOrderModel = Order & {
  cart: CreatedCart;
  fulfillment_history: FulfillmentHistory[];
  fulfillment_status?: FulfillmentHistory;
  discount_amount: string;
  fulfillment_cost: string;
  fulfillment_time?: string;
  notes: string;
  payments: PaymentItem[];
  payment_method?: StorePaymentMethod;
  fulfillment_channel?: FulfillmentChannel;

  can_be_cancelled: boolean;
  can_be_edited: boolean;
  can_be_refunded: boolean;
  is_complete: boolean;
  is_payment_pending: boolean;
};

export interface CartItemDto {
  id?: string; // for update
  product_id: string;
  quantity: number;
  product_type: string;
  item_price?: number; // Only used for variable price products
}

export type BaseAddress = {
  address_1: string;
  address_2: string;
  city: string;
  country: string;
  state: string;
};

export type AddressDto = BaseAddress;

export type Address = BaseAddress & {
  id: string;
  display_name: string;
};

export type CartPaymentDto = {
  discount_amount?: number;
  amount_received?: string;
  change?: string;
  discount_percentage?: number;
  payment_method_id: string;
};

export type CreatePaymentDto = Omit<
  CartPaymentDto,
  "discount_amount" | "change" | "discount_percentage"
>;

export interface CreateStoreOrderDto {
  // 0. Store ID
  store_id: string;

  // 1. User data
  customer_id: string | null;

  // 2. Cart data
  cart_items: CartItemDto[];

  fulfillment: FulfillmentDto;

  payment: CartPaymentDto;

  // 3. Order options

  // 5. Payment options
  // payment_method: string; // TODO: Should be a separate model

  is_draft?: boolean;

  order_id?: string; // used for updating an order
  outlet_id?: string; // used for placing orders for an outlet

  notes?: string;
}

export interface OrderStatItem {
  count: number;
  total: number;
}

export interface CancelOrderDto {
  reason?: string;
}

// TODO: Implement
// export type OrderListItem = {};

export type OrderListResponse = PaginatedResponse<RetrievedOrderModel>;

export type OrderEventListItem = {
  id: string;
  event_type: string;
  order: number;
  created_at: string;
  created_by: ReadOnlyUser;
  event_data: object;
};

export type OrderEventListResponse = PaginatedResponse<OrderEventListItem>;

export enum SupportedInvoiceChannels {
  WhatsApp = "whatsapp",
}

export type SendOrderInvoiceDto = {
  channel: SupportedInvoiceChannels;
};
