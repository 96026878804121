import { useMutation, useQuery } from "react-query";
import type { ProductListResponse } from "./product.service";
import { ProductService } from "./product.service";

import {
  AllProduct,
  BulkStockUpdateDto,
  CreateProductDto,
  DownloadInventoryFilter,
  Product,
  ProductFilter,
  ProductStats,
  UpdateProductDto,
} from "./product.model";
import { QueryOpts } from "../../../../models/base.model";

export const useCreateProductMutation = () =>
  useMutation<Product, Error, CreateProductDto>((formData) => {
    const productService = new ProductService();
    return productService.createProduct(formData);
  });

export const useGetProductsForStoreQuery = (
  storeId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<ProductListResponse, Error>(
    `getProductsForStore${storeId}_${JSON.stringify(queryOpts)}`,
    () => {
      const productService = new ProductService();

      return productService.getStoreInventory(
        storeId,
        queryOpts?.filters as ProductFilter,
      );
    },

    {
      enabled: Boolean(storeId),
      initialData: queryOpts?.initialData,
    },
  );

export const useDownloadStoreInventory = (
  storeId: string,
  filters: DownloadInventoryFilter,
) =>
  useMutation<void, Error>("downloadStoreInventory", () => {
    const productService = new ProductService();
    return productService.downloadStoreInventory(storeId, filters);
  });

export const useGetAllProductsForStoreQuery = (
  storeSlug: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<AllProduct[], Error>(
    `getAllProductsForStore${storeSlug}_${JSON.stringify(queryOpts)}`,
    () => {
      const productService = new ProductService();
      return productService.getAllProductsForStore(
        storeSlug,
        queryOpts?.filters as ProductFilter,
      );
    },

    {
      enabled: Boolean(storeSlug) && (queryOpts?.enabled ?? true),
    },
  );

export const useGetProductQuery = (slug: string, queryOpts?: QueryOpts) =>
  useQuery<Product, Error>(
    `getProduct${slug}`,
    () => {
      const productService = new ProductService();
      return productService.getProduct(slug);
    },
    {
      enabled: Boolean(slug),
      refetchOnMount: queryOpts?.noRefetch ?? undefined,
      refetchOnReconnect: queryOpts?.noRefetch ?? undefined,
      refetchOnWindowFocus: queryOpts?.noRefetch ?? undefined,
    },
  );

export const useGetProductStatsQuery = (slug: string, queryOpts: QueryOpts) =>
  useQuery<ProductStats, Error>(
    ["getProductStats", slug, queryOpts],
    () => {
      const productService = new ProductService();
      return productService.getProductStats(slug, queryOpts);
    },
    {
      enabled: Boolean(slug),
    },
  );

export const useUpdateProductMutation = () =>
  useMutation<Product, Error, UpdateProductDto>((formData) => {
    const productService = new ProductService();
    return productService.updateProduct(formData);
  });
export const useArchiveProductMutation = () =>
  useMutation<Product, Error, string>((id) => {
    const productService = new ProductService();
    return productService.archive(id);
  });

export const useEnableProductMutation = () =>
  useMutation<Product, Error, string>((id) => {
    const productService = new ProductService();
    return productService.enable(id);
  });

export const useBulkUpdateProductStockMutation = () =>
  useMutation<
    void,
    Error,
    {
      storeSlug: string;
      data: BulkStockUpdateDto;
    }
  >(({ data, storeSlug }) => {
    const productService = new ProductService();
    return productService.bulkUpdateProductStock(storeSlug, data);
  });

export { useGetEcommerceProductQuery } from "../../../ecommerce/product/product.ecommerce.hooks";
