import { format, formatDistance, isToday, isYesterday } from "date-fns";

export const formatDate = (dateStr: string, showTime = true): string => {
  const strFormat = showTime ? "dd MMM, yyyy HH:mm" : "dd MMM, yyyy";
  return format(new Date(dateStr), strFormat);
};

/**
 * Format a date string to a day string without the time - 2023-01-23
 * @param dateStr
 */
export const formatDay = (dateStr: string): string => {
  if (!dateStr) return "";

  return format(new Date(dateStr), "yyyy-MM-dd");
};

export const getRelativeDayString = (dayStr: string): string => {
  const day = new Date(dayStr);

  if (isToday(day)) {
    return "Today";
  }

  if (isYesterday(day)) {
    return "Yesterday";
  }

  return formatDistance(new Date(day), new Date(), {
    addSuffix: true,
  });
};

export const getLastMonth = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, 1);
};
