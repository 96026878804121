import { BaseService } from "../../../../services/base.service";
import {
  Account,
  AccountListResponse,
  AllAccountsResponse,
  CreateAccountDto,
  UpdateAccountDto,
} from "./account.model";
import { ApiClientResponse } from "../../../../models/responses";
import { QueryOpts } from "../../../../models/base.model";

export class AccountService extends BaseService {
  getAccount(storeId: string, accountId: string): Promise<Account> {
    const reqUrl = `/accounts/${storeId}/${accountId}/`;
    const req = this.apiClient.get<ApiClientResponse<Account>>(reqUrl);

    return this.makeRequest<Account>(req, reqUrl);
  }

  getStoreAccounts(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<AccountListResponse> {
    const reqUrl = `/accounts/${storeId}/`;
    const req = this.apiClient.get<ApiClientResponse<AccountListResponse>>(
      reqUrl,
      {
        params: queryOpts.filters,
      },
    );

    return this.makeRequest<AccountListResponse>(req, reqUrl);
  }

  getAllStoreAccounts(storeId: string): Promise<AllAccountsResponse> {
    const reqUrl = `/accounts/${storeId}/options/all/`;
    const req =
      this.apiClient.get<ApiClientResponse<AllAccountsResponse>>(reqUrl);

    return this.makeRequest<AllAccountsResponse>(req, reqUrl);
  }

  createAccount(storeId: string, data: CreateAccountDto): Promise<Account> {
    const reqUrl = `/accounts/${storeId}/`;
    const req = this.apiClient.post<ApiClientResponse<Account>>(reqUrl, data);

    return this.makeRequest<Account>(req, reqUrl);
  }

  updateAccount(
    storeId: string,
    accountId: string,
    data: UpdateAccountDto,
  ): Promise<Account> {
    const reqUrl = `/accounts/${storeId}/${accountId}/`;
    const req = this.apiClient.patch<ApiClientResponse<Account>>(reqUrl, data);

    return this.makeRequest<Account>(req, reqUrl);
  }
}
