export const isServer = () => typeof window === "undefined";

export interface GroupEntityByDayResponse<T> {
  [key: string]: T[];
}

export type GroupEntityByDayProp<T> = {
  created_at: string;
} & T;

export const groupEntityByDay = <T>(
  items: GroupEntityByDayProp<T>[],
): GroupEntityByDayResponse<T> => {
  const response: GroupEntityByDayResponse<T> = {};

  items.forEach((item) => {
    const day: string = item.created_at.split("T")[0];

    if (response[day]) {
      response[day].push(item);
    } else {
      response[day] = [item];
    }
  });

  return response;
};

export const isNotEmptyArray = (array: any[] | undefined): boolean => {
  return Array.isArray(array) && array.length > 0;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getKeyFromLocalStorage = (key: string): string | null => {
  if (isServer()) {
    return null;
  }

  return localStorage.getItem(key);
};
