import { BaseService } from "./base.service";
import { FulfillmentChannel } from "../modules/suite/order/order.model";
import { ApiClientResponse } from "../models/responses";

export class FulfillmentService extends BaseService {
  getFulfillmentChannels(storeId: string): Promise<FulfillmentChannel[]> {
    const reqUrl = `/fulfillment/${storeId}/channels/`;
    const req =
      this.apiClient.get<ApiClientResponse<FulfillmentChannel[]>>(reqUrl);

    return this.makeRequest<FulfillmentChannel[]>(req, reqUrl);
  }

  getAllFulfillmentChannels(): Promise<FulfillmentChannel[]> {
    const reqUrl = `/fulfillment/channels/`;
    const req =
      this.apiClient.get<ApiClientResponse<FulfillmentChannel[]>>(reqUrl);

    return this.makeRequest<FulfillmentChannel[]>(req, reqUrl);
  }
}
