import { useMutation, useQuery } from "react-query";
import { CreatePayrollDto, Payroll } from "../models/payroll.model";
import { PayrollService } from "../services/payroll.service";

export const useCreatePayroll = () =>
  useMutation<Payroll, Error, { storeId: string; data: CreatePayrollDto }>(
    (data) => {
      const payrollService = new PayrollService();
      return payrollService.createPayrollForStore(data.storeId, data.data);
    },
  );

export const useGetPayrolls = (storeId: string) =>
  useQuery(
    `getStorePayrolls_${storeId}`,
    () => {
      const payrollService = new PayrollService();
      return payrollService.getPayrollsForStore(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetPayroll = (storeId: string, payrollId: string) =>
  useQuery(
    `getStorePayroll_${storeId}_${payrollId}`,
    () => {
      const payrollService = new PayrollService();
      return payrollService.getPayrollForStore(storeId, payrollId);
    },
    {
      enabled: Boolean(storeId && payrollId),
    },
  );

export const useAddTeamMemberToPayroll = () => {
  const payrollService = new PayrollService();
  return useMutation<
    Payroll,
    Error,
    {
      storeId: string;
      payrollId: string;
      teamMemberId: string;
    }
  >((data) => {
    return payrollService.addTeamMemberToPayroll(
      data.storeId,
      data.payrollId,
      data.teamMemberId,
    );
  });
};

export const useAddAllTeamMembersToPayroll = () => {
  const payrollService = new PayrollService();
  return useMutation<
    Payroll,
    Error,
    {
      storeId: string;
      payrollId: string;
    }
  >((data) => {
    return payrollService.addAllTeamMembersToPayroll(
      data.storeId,
      data.payrollId,
    );
  });
};

export const useResetPayrollMutation = () => {
  const payrollService = new PayrollService();
  return useMutation<
    Payroll,
    Error,
    {
      storeId: string;
      payrollId: string;
    }
  >((data) => {
    return payrollService.resetPayroll(data.storeId, data.payrollId);
  });
};

export const useMakePaymentMutation = () => {
  const payrollService = new PayrollService();
  return useMutation<
    Payroll,
    Error,
    {
      storeId: string;
      payrollId: string;
      itemId: string;
    }
  >((data) => {
    return payrollService.makePaymentForLineItem(
      data.storeId,
      data.payrollId,
      data.itemId,
    );
  });
};
