import { BaseModel } from "./base.model";
import { Country, GeoState } from "./country.model";
import { FulfillmentChannel } from "../modules/suite/order/order.model";
import {
  StorePaymentMethod,
  StorePaymentMethodDto,
} from "../modules/suite/payments/payment.model";
import { PublicMediaItem } from "./media.model";
import { ShortProduct } from "../modules/suite/inventory/product/product.model";
import { StoreCategory } from "../modules/suite/storeCategory/storeCategory.model";
import { WebLink } from "../modules/suite/webLink/webLink.model";

export enum StoreRoles {
  Owner = "owner",
  Manager = "manager",
  Admin = "admin",
  Staff = "staff",
}

export type PermissionsModelV1 = {
  permissionId?: string;
  adminOnly?: boolean;
  allUsers?: boolean;
  devOnly?: boolean;
  role?: string;
};

export type StoreApprovalSetting = {
  require_prodtrack_approval: boolean;
  require_smartstock_approval: boolean;
};

export interface Store extends BaseModel {
  id: string;
  currency: string;
  name: string;
  description: string;
  slug: string;
  is_verified: boolean;
  country: Country;
  state: GeoState;
  logo: PublicMediaItem | null;
  min_profit_percent: number;
  approval_settings?: StoreApprovalSetting;
}

export type ShortStore = {
  id: string;
  name: string;
  slug: string;
  logo: PublicMediaItem | null;
  country: Country;
};

export type CreateStoreDto = {
  name: string;
  country_id: string;
  state_id: string;
  description: string;
  summary?: string;
  category_id: number | string;
  currency: string;
};

export type ProductPerfItem = {
  units_sold: number;
  times_purchased: number;
  revenue: number;
  product: ShortProduct;
};

export type ProductPerfStats = {
  by_units_sold: ProductPerfItem[];
  by_times_purchased: ProductPerfItem[];
  by_revenue: ProductPerfItem[];
};

export type BaseStoreSetting = {
  currency: string;
  description: string;
  slug: string;
  payroll_day: string;
  whatsapp_number?: string;
};

export type UpdateStoreSettingsDto = BaseStoreSetting & {
  name: string;
  state_id: string;
  currency_id: string;
  fulfillment_channel_ids: string[];
  logo_id: string;
  min_profit_percent: string;

  payment_methods: StorePaymentMethodDto[];
  approval_settings?: StoreApprovalSetting;

  category_id?: string;
};

export type StoreSetting = BaseStoreSetting & {
  fulfillment_channels: FulfillmentChannel[];
  payment_methods: StorePaymentMethod[];
  logo?: PublicMediaItem;
  category?: StoreCategory;
  links?: WebLink[];
};

export type MonthlyOrderStats = {
  [key: string]: {
    total_amount: number;
    total_orders: number;
    cumulative_total: number;
  };
};

export interface CustomerOrderStatsItem {
  customer: {
    id: string;
    display_name: string;
    phone_number: string;
  };
  total_amount: number;
  count: number;
}

export type CustomerOrderStats = {
  by_count: CustomerOrderStatsItem[];
  by_total_amount: CustomerOrderStatsItem[];
};

export type OnboardingChecklist = {
  create_product: boolean;
  create_order: boolean;
  set_fulfillment_channels: boolean;
  set_store_logo: boolean;
  // publish_store: boolean;
  add_supplier: boolean;
  add_payment_method: boolean;
  upload_first_file: boolean;
};

export type StoreDashboardConfig = {
  get_started: {
    enabled: boolean;
    checklist: OnboardingChecklist;
  };

  overview: {
    enabled: boolean;
  };

  sales: {
    enabled: boolean;
  };

  payments: {
    enabled: boolean;
  };

  inventory: {
    enabled: boolean;
  };

  customers: {
    enabled: boolean;
  };

  suppliers: {
    enabled: boolean;
  };

  logistics: {
    enabled: boolean;
  };
};
