import { useMutation, useQuery } from "react-query";
import {
  CheckoutCustomerResponse,
  CreateCustomerDto,
  Customer,
  CustomerListResponse,
  CustomerStat,
  DebtorItem,
  FindCustomerDto,
  UpdateCustomerDto,
} from "./customer.model";
import { CustomerService } from "./customer.service";
import { PaginatedResponse, QueryOpts } from "../../../models/base.model";
import { RetrievedOrderModel } from "../order/order.model";
import { EleoTimePeriod } from "../storeReporting/storeReporting.model";

export const useFindCustomer = () =>
  useMutation<Customer | null, Error, FindCustomerDto>(
    `findCustomer`,
    (data: FindCustomerDto) => {
      const customerService = new CustomerService();
      return customerService.findCustomer(data);
    },
  );

export const useCreateCustomer = () =>
  useMutation<Customer | null, Error, CreateCustomerDto>(
    `create_customer`,
    (data: CreateCustomerDto) => {
      const customerService = new CustomerService();
      return customerService.createCustomer(data);
    },
  );
export const useUpdateCustomer = () =>
  useMutation<Customer | null, Error, UpdateCustomerDto>(
    `update_Customer`,
    (data: UpdateCustomerDto) => {
      const customerService = new CustomerService();
      return customerService.updateCustomer(data);
    },
  );

export const useGetCustomersForStore = (
  storeId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<CustomerListResponse, Error>(
    `getCustomers_${storeId}_${JSON.stringify(queryOpts)}`,
    async () => {
      const customerService = new CustomerService();

      return customerService.getCustomersForStore(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetCheckoutCustomers = (
  storeId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<CheckoutCustomerResponse, Error>(
    ["checkoutCustomers", storeId, queryOpts],
    () => {
      const customerService = new CustomerService();
      return customerService.getCheckoutCustomers(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetCustomerById = (
  storeId: string,
  customerId: string,
  queryOpts: QueryOpts = {
    enabled: true,
  },
) =>
  useQuery<Customer, Error>(
    `customer_${storeId}_${customerId}`,
    async () => {
      const customerService = new CustomerService();

      return customerService.getCustomerById(storeId, customerId);
    },
    {
      enabled: queryOpts?.enabled && Boolean(storeId && customerId),
      refetchOnMount: queryOpts?.noRefetch ?? undefined,
      refetchOnReconnect: queryOpts?.noRefetch ?? undefined,
      refetchOnWindowFocus: queryOpts?.noRefetch ?? undefined,
    },
  );

export const useGetOrdersForCustomer = (
  storeId: string,
  customerId: string,
  queryOpts: QueryOpts = {},
) =>
  useQuery<PaginatedResponse<RetrievedOrderModel>, Error>(
    `customer_orders_${storeId}_${customerId}_${JSON.stringify(queryOpts)}`,
    async () => {
      const customerService = new CustomerService();

      return customerService.getOrdersForCustomer(
        storeId,
        customerId,
        queryOpts,
      );
    },
    {
      enabled: Boolean(storeId && customerId),
    },
  );

export const useGetCustomerStats = (
  storeId: string,
  customerId: string,
  period: EleoTimePeriod | string,
) =>
  useQuery<CustomerStat, Error>(
    `storeCustomerStats_${storeId}_${customerId}_${period}`,
    () => {
      const customerService = new CustomerService();
      return customerService.getCustomerStats(storeId, customerId, period);
    },
    {
      enabled: Boolean(storeId && customerId),
    },
  );
export const useGetDebtors = (
  storeId: string,
  period?: EleoTimePeriod | string,
) =>
  useQuery<DebtorItem[], Error>(
    `debtors_${storeId}_${period}`,
    () => {
      const customerService = new CustomerService();
      return customerService.getDebtors(storeId, period);
    },
    {
      enabled: Boolean(storeId),
    },
  );
