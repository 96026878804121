import { BaseService } from "./base.service";
import { ApiClientResponse } from "../models/responses";
import {
  CreateProductCategoryDto,
  ProductCategory,
  ProductCategoryTree,
} from "../models/productCategory.model";

export class ProductCategoryService extends BaseService {
  getProductCategoryTree(): Promise<ProductCategoryTree> {
    const reqUrl = `/product_categories/tree/`;
    const req =
      this.apiClient.get<ApiClientResponse<ProductCategoryTree>>(reqUrl);
    return this.makeRequest<ProductCategoryTree>(req, reqUrl);
  }

  getProductCategories(): Promise<ProductCategory[]> {
    const reqUrl = `/product_categories/flat/`;
    const req =
      this.apiClient.get<ApiClientResponse<ProductCategory[]>>(reqUrl);
    return this.makeRequest<ProductCategory[]>(req, reqUrl);
  }

  addProductCategory(
    productCategory: CreateProductCategoryDto,
  ): Promise<ProductCategory> {
    const reqUrl = `/product_categories/`;
    const req = this.apiClient.post<ApiClientResponse<ProductCategory>>(
      reqUrl,
      productCategory,
    );

    return this.makeRequest<ProductCategory>(req, reqUrl);
  }

  deleteProductCategory(slug: string): Promise<null> {
    const reqUrl = `/product_categories/${slug}/`;
    const req = this.apiClient.delete<ApiClientResponse<null>>(reqUrl);
    return this.makeRequest<null>(req, reqUrl);
  }
}
