import { BaseService } from "./base.service";
import {
  AllSuppliersResponse,
  CreateSupplierDto,
  Supplier,
  UpdateSupplierDto,
} from "../models/supplier.model";
import { ApiClientResponse } from "../models/responses";
import { PaginatedResponse, QueryOpts } from "../models/base.model";

export class SupplierService extends BaseService {
  createSupplier(
    storeId: string,
    supplierData: CreateSupplierDto,
  ): Promise<Supplier> {
    const reqUrl = `suppliers/${storeId}/`;
    const req = this.apiClient.post<ApiClientResponse<Supplier>>(
      reqUrl,
      supplierData,
    );

    return this.makeRequest<Supplier>(req, reqUrl);
  }

  getStoreSuppliers(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<Supplier>> {
    const reqUrl = `suppliers/${storeId}/`;
    const req = this.apiClient.get<
      ApiClientResponse<PaginatedResponse<Supplier>>
    >(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest<PaginatedResponse<Supplier>>(req, reqUrl);
  }

  getAllStoreSuppliers(storeId: string): Promise<AllSuppliersResponse> {
    const reqUrl = `suppliers/${storeId}/all/`;
    const req =
      this.apiClient.get<ApiClientResponse<AllSuppliersResponse>>(reqUrl);

    return this.makeRequest<AllSuppliersResponse>(req, reqUrl);
  }

  getStoreSupplier(storeId: string, supplierId: string): Promise<Supplier> {
    const reqUrl = `suppliers/${storeId}/${supplierId}/`;
    const req = this.apiClient.get<ApiClientResponse<Supplier>>(reqUrl);

    return this.makeRequest<Supplier>(req, reqUrl);
  }

  updateStoreSupplier(
    storeId: string,
    supplierId: string,
    data: UpdateSupplierDto,
  ): Promise<Supplier> {
    const reqUrl = `suppliers/${storeId}/${supplierId}/`;
    const req = this.apiClient.patch<ApiClientResponse<Supplier>>(reqUrl, data);

    return this.makeRequest<Supplier>(req, reqUrl);
  }
}
