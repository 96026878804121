import { BasePublicService } from "../../../services/public/base.public.service";
import { ApiClientResponse } from "../../../models/responses";
import { GlobalSearchParams, SearchResponse } from "./search.model";

export class SearchService extends BasePublicService {
  async search<T>(
    searchParams: GlobalSearchParams,
  ): Promise<SearchResponse<T>> {
    const reqUrl = "/search";
    const req = this.apiClient.get<ApiClientResponse<SearchResponse<T>>>(
      reqUrl,
      {
        params: {
          ...searchParams,
        },
      },
    );

    return this.makeRequest<SearchResponse<T>>(req, reqUrl);
  }
}
