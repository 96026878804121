import { useQuery } from "react-query";
import {
  EntityEventDetail,
  EntityEventFilteringResponse,
  GetEventsResponse,
} from "./entityEvent.model";
import { EntityEventService } from "./entityEvent.service";
import { QueryOpts } from "../../../models/base.model";

export const useGetStoreEvents = (storeId: string, queryParams: QueryOpts) =>
  useQuery<GetEventsResponse, Error>(
    `storeEvents_${storeId}_${JSON.stringify(queryParams)}`,
    () => {
      const entityEventService = new EntityEventService();
      return entityEventService.getStoreEvents(storeId, queryParams);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetEvent = (storeId: string, eventId: string) =>
  useQuery<EntityEventDetail, Error>(
    `event_${storeId}_${eventId}`,
    () => {
      const entityEventService = new EntityEventService();
      return entityEventService.getEvent(storeId, eventId);
    },
    {
      enabled: Boolean(storeId && eventId),
    },
  );

export const useGetEventFilteringOptions = (
  storeId: string,
  queryParams: QueryOpts,
) =>
  useQuery<EntityEventFilteringResponse, Error>(
    `storeEventFilters_${storeId}_${JSON.stringify(queryParams)}`,
    () => {
      const entityEventService = new EntityEventService();
      return entityEventService.getStoreEventFilters(storeId, queryParams);
    },
    {
      enabled: Boolean(storeId),
    },
  );
