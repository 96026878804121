import { useMutation, useQuery } from "react-query";
import {
  CancelOrderDto,
  CreatePaymentDto,
  CreateStoreOrderDto,
  Order,
  OrderEventListResponse,
  OrderListResponse,
  RetrievedOrderModel,
  SendOrderInvoiceDto,
  ShortOrder,
} from "./order.model";
import { OrderService } from "./order.service";
import { PaginatedResponse, QueryOpts } from "../../../models/base.model";
import { CheckoutProduct } from "../inventory/product/product.model";

export const useCreateStoreOrderMutation = () =>
  useMutation<Order, Error, CreateStoreOrderDto>((formData) => {
    const orderService = new OrderService();
    return orderService.createStoreOrder(formData);
  });

export const usePlaceStoreOrderMutation = (storeId: string) =>
  useMutation<Order, Error, CreateStoreOrderDto>((formData) => {
    const orderService = new OrderService();
    return orderService.placeStoreOrder(storeId, formData);
  });

export const useGetStoreOrdersQuery = (
  storeSlug: string,
  queryOpts: QueryOpts,
) =>
  useQuery<OrderListResponse, Error>(
    `getOrdersForStore${storeSlug}_${JSON.stringify(queryOpts?.filters)}`,
    () => {
      const orderService = new OrderService();
      return orderService.getStoreOrders(storeSlug, queryOpts);
    },
    {
      enabled: Boolean(storeSlug),
    },
  );

export const useGetStoreOrder = (storeId: string, orderId: string) =>
  useQuery<RetrievedOrderModel, Error>(
    `getOrderForStore_${storeId}_${orderId}`,
    () => {
      const orderService = new OrderService();
      return orderService.getStoreOrder(storeId, orderId);
    },
    {
      enabled: Boolean(storeId && orderId),
    },
  );

export const useGetOrderEvents = (
  storeId: string,
  orderId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<OrderEventListResponse, Error>(
    `getOrderEvents_${storeId}_${orderId}_${JSON.stringify(queryOpts)}`,
    () => {
      const orderService = new OrderService();
      return orderService.getOrderEvents(storeId, orderId, queryOpts);
    },
    {
      enabled: Boolean(storeId && orderId),
    },
  );

export const useGetOrdersForProduct = (
  productId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<PaginatedResponse<ShortOrder>, Error>(
    `getOrdersForProduct__${productId}_${queryOpts?.filters?.toString()}`,
    () => {
      const orderService = new OrderService();
      return orderService.getOrdersForProduct(productId, queryOpts);
    },
    {
      enabled: Boolean(productId),
    },
  );
export const useGetOrdersForBundle = (bundleId: string, queryOpts: QueryOpts) =>
  useQuery<PaginatedResponse<ShortOrder>, Error>(
    ["getOrdersForBundle", bundleId, queryOpts],
    () => {
      const orderService = new OrderService();
      return orderService.getOrdersForBundle(bundleId, queryOpts);
    },
    {
      enabled: Boolean(bundleId),
    },
  );

export const useCancelStoreOrderMutation = () =>
  useMutation<
    Order,
    Error,
    { storeId: string; orderId: string; data: CancelOrderDto }
  >(({ storeId, orderId, data }) => {
    const orderService = new OrderService();
    return orderService.cancelStoreOrder(storeId, orderId, data);
  });
export const useSendOrderInvoice = (storeId: string, orderId: string) =>
  useMutation<void, Error, SendOrderInvoiceDto>((payload) => {
    const orderService = new OrderService();
    return orderService.sendOrderInvoice(storeId, orderId, payload);
  });

export const useCreateOrderPayment = () =>
  useMutation<
    Order,
    Error,
    {
      storeId: string;
      orderId: string;
      paymentData: CreatePaymentDto;
    }
  >(({ storeId, orderId, paymentData }) => {
    const orderService = new OrderService();
    return orderService.createOrderPayment(storeId, orderId, paymentData);
  });

export const useGetCheckoutProductsForStoreQuery = (storeId: string) =>
  useQuery<CheckoutProduct[], Error>(
    `getCheckoutProductsForStore${storeId}`,
    () => {
      const orderService = new OrderService();
      return orderService.getCheckoutProductsForStore(storeId);
    },

    {
      enabled: Boolean(storeId),
      refetchOnWindowFocus: true,
    },
  );
