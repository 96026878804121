import { useMutation, useQuery } from "react-query";
import {
  CreatePurchaseHistoryDto,
  PurchaseHistory,
} from "../models/purchases.model";
import { PurchaseService } from "../services/purchases.service";

export const useCreatePurchaseHistoryMutation = () =>
  useMutation<
    PurchaseHistory,
    Error,
    {
      storeId: string;
      data: CreatePurchaseHistoryDto;
    }
  >(({ storeId, data }) => {
    const purchaseService = new PurchaseService();
    return purchaseService.createPurchase(storeId, data);
  });

export const useGetPurchases = (storeId: string) =>
  useQuery(
    `getStorePurchases_${storeId}`,
    () => {
      const purchaseService = new PurchaseService();
      return purchaseService.getPurchases(storeId);
    },
    {
      enabled: Boolean(storeId),
    },
  );
