import { BaseService } from "../../../services/base.service";
import {
  BankAccountDetailResponse,
  GetBankResponse,
} from "./financialServices.model";
import { ApiClientResponse } from "../../../models/responses";

export class FinancialServicesService extends BaseService {
  getBanks(): Promise<GetBankResponse> {
    const reqUrl = `/banking/banks/`;
    const req = this.apiClient.get<ApiClientResponse<GetBankResponse>>(reqUrl);

    return this.makeRequest<GetBankResponse>(req, reqUrl);
  }

  getBankAccountDetail(
    bankCode: string,
    accountNumber: string,
  ): Promise<BankAccountDetailResponse> {
    const reqUrl = `/banking/account-details/`;
    const req = this.apiClient.get<
      ApiClientResponse<BankAccountDetailResponse>
    >(reqUrl, {
      params: {
        bank_code: bankCode,
        account_number: accountNumber,
      },
    });

    return this.makeRequest<BankAccountDetailResponse>(req, reqUrl);
  }
}
