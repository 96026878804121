import { BaseService } from "../../../services/base.service";
import { CApiStore } from "./store.ecommerce.model";

class BaseCustomerStoreService extends BaseService {
  getStoreBySlug(storeSlug: string): Promise<CApiStore> {
    const reqUrl = `${process.env.NEXT_PUBLIC_CUSTOMER_API_URL}stores/${storeSlug}`;

    const req = fetch(reqUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return this.makeRequestWithFetch<CApiStore>(req, reqUrl);
  }
}

export const StoreEcommerceService = new BaseCustomerStoreService({
  baseURL: process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
  allowUnauthenticated: true,
});
