/* eslint-disable @typescript-eslint/no-empty-function */
import type { NextWebVitalsMetric } from "next/app";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import type { LayoutOptions } from "@eleo/components/components/OjaUIProvider/OjaUIProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import "firebase/auth";
import "firebase/performance";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../contexts/auth.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type React from "react";
import { useEffect } from "react";
import "react-phone-number-input/style.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import { setupEleoApp } from "@eleo/core";
import { logger } from "@eleo/core/libs/logger";
import withAuth from "@eleo/suite/components/withAuth/withAuth";

export interface CustomAppProps extends AppProps {
  Component: React.ComponentType & {
    layoutOptions?: LayoutOptions;
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
    },
  },
});

const EleoSuite = ({ Component, pageProps, router }: CustomAppProps) => {
  const layoutOptions = Component.layoutOptions || {};

  const AuthComponent = withAuth(Component, router);

  useEffect(() => {
    setupEleoApp({
      serviceName: "suite",
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />

      <AuthProvider layoutOptions={layoutOptions}>
        <AuthComponent {...pageProps} />
      </AuthProvider>
    </QueryClientProvider>
  );
};

EleoSuite.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps> => {
  const ctx = await App.getInitialProps(context);

  return { ...ctx };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  logger.info(JSON.stringify(metric));
}

export default EleoSuite;
