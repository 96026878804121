import { BaseService } from "../../../services/base.service";
import {
  CheckoutCustomerResponse,
  CreateCustomerDto,
  Customer,
  CustomerListResponse,
  CustomerStat,
  DebtorItem,
  FindCustomerDto,
  UpdateCustomerDto,
} from "./customer.model";
import { ApiClientResponse } from "../../../models/responses";
import { PaginatedResponse, QueryOpts } from "../../../models/base.model";
import { RetrievedOrderModel } from "../order/order.model";
import { EleoTimePeriod } from "../storeReporting/storeReporting.model";

export class CustomerService extends BaseService {
  findCustomer({
    store_id: storeId,
    phone_number,
  }: FindCustomerDto): Promise<Customer | null> {
    const reqUrl = `/stores/${storeId}/customers/?phone_number=${encodeURIComponent(
      phone_number,
    )}`;
    const req = this.apiClient.get<ApiClientResponse<Customer | null>>(reqUrl);

    return this.makeRequest<Customer | null>(req, reqUrl);
  }

  createCustomer({
    store_id: storeId,
    ...data
  }: CreateCustomerDto): Promise<Customer> {
    const reqUrl = `/stores/${storeId}/customers/`;
    const req = this.apiClient.post<ApiClientResponse<Customer>>(reqUrl, data);

    return this.makeRequest<Customer>(req, reqUrl);
  }

  updateCustomer({
    store_id: storeId,
    id,
    ...data
  }: UpdateCustomerDto): Promise<Customer> {
    const reqUrl = `/stores/${storeId}/customers/${id}/`;
    const req = this.apiClient.patch<ApiClientResponse<Customer>>(reqUrl, data);

    return this.makeRequest<Customer>(req, reqUrl);
  }

  getCustomersForStore(
    storeId: string,
    queryOpts?: QueryOpts,
  ): Promise<CustomerListResponse> {
    const reqUrl = `/stores/${storeId}/customers/`;
    const req = this.apiClient.get<ApiClientResponse<CustomerListResponse>>(
      reqUrl,
      {
        params: queryOpts?.filters,
      },
    );

    return this.makeRequest<CustomerListResponse>(req, reqUrl);
  }

  getCustomerById(storeId: string, customerId: string): Promise<Customer> {
    const reqUrl = `/stores/${storeId}/customers/${customerId}/`;
    const req = this.apiClient.get<ApiClientResponse<Customer>>(reqUrl);

    return this.makeRequest<Customer>(req, reqUrl);
  }

  getOrdersForCustomer(
    storeId: string,
    customerId: string,
    queryOpts: QueryOpts,
  ): Promise<PaginatedResponse<RetrievedOrderModel>> {
    const reqUrl = `/stores/${storeId}/customers/${customerId}/orders/?page=${
      queryOpts.page || "1"
    }`;
    const req = this.apiClient.get<
      ApiClientResponse<PaginatedResponse<RetrievedOrderModel>>
    >(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest<PaginatedResponse<RetrievedOrderModel>>(
      req,
      reqUrl,
    );
  }

  getCustomerStats(
    storeId: string,
    customerId: string,
    period: EleoTimePeriod | string,
  ): Promise<CustomerStat> {
    const reqUrl = `/store-stats/${storeId}/customers/${customerId}/`;
    const req = this.apiClient.get<ApiClientResponse<CustomerStat>>(reqUrl, {
      params: {
        period,
      },
    });

    return this.makeRequest<CustomerStat>(req, reqUrl);
  }

  getDebtors(
    storeId: string,
    period?: EleoTimePeriod | string,
  ): Promise<DebtorItem[]> {
    const reqUrl = `/customers/${storeId}/debtors/`;
    const req = this.apiClient.get<ApiClientResponse<DebtorItem[]>>(reqUrl, {
      params: {
        period,
      },
    });

    return this.makeRequest<DebtorItem[]>(req, reqUrl);
  }

  getCheckoutCustomers(
    storeId: string,
    queryOpts?: QueryOpts,
  ): Promise<CheckoutCustomerResponse> {
    const reqUrl = `/customers/${storeId}/checkout/`;
    const req = this.apiClient.get<ApiClientResponse<CheckoutCustomerResponse>>(
      reqUrl,
      {
        params: {
          ...(queryOpts?.filters || {}),
        },
      },
    );

    return this.makeRequest<CheckoutCustomerResponse>(req, reqUrl);
  }
}
