import { BaseService } from "../../../services/base.service";
import { ApiClientResponse } from "../../../models/responses";
import {
  PaymentFilter,
  PaymentItem,
  PaymentMethodType,
  StorePaymentListResponse,
  StorePaymentMethod,
} from "./payment.model";
import { QueryOpts } from "@root/libs/core/src";

export class PaymentService extends BaseService {
  namespace = "payment-methods";

  getStorePaymentMethods(
    storeId: string,
    queryOpts?: QueryOpts,
  ): Promise<StorePaymentMethod[]> {
    const reqUrl = `/payment-methods/store/${storeId}/`;
    const req = this.apiClient.get<ApiClientResponse<StorePaymentMethod[]>>(
      reqUrl,
      {
        params: queryOpts?.filters || {},
      },
    );

    return this.makeRequest<StorePaymentMethod[]>(req, reqUrl);
  }

  getAllPaymentMethodTypes(): Promise<PaymentMethodType[]> {
    const reqUrl = `/payment-methods/all/`;
    const req =
      this.apiClient.get<ApiClientResponse<PaymentMethodType[]>>(reqUrl);

    return this.makeRequest<PaymentMethodType[]>(req, reqUrl);
  }

  getPaymentsForStore(
    storeId: string,
    filters: PaymentFilter,
  ): Promise<StorePaymentListResponse> {
    const reqUrl = `/payments/${storeId}/`;
    const req = this.apiClient.get<ApiClientResponse<StorePaymentListResponse>>(
      reqUrl,
      {
        params: filters,
      },
    );

    return this.makeRequest<StorePaymentListResponse>(req, reqUrl);
  }

  confirmPayment(storeId: string, paymentId: string): Promise<PaymentItem> {
    const reqUrl = `/payments/${storeId}/${paymentId}/confirm/`;
    const req = this.apiClient.post<ApiClientResponse<PaymentItem>>(reqUrl);

    return this.makeRequest<PaymentItem>(req, reqUrl);
  }

  enableStoreCreditPaymentMethod(storeId: string): Promise<null> {
    const reqUrl = `/payment-methods/${storeId}/credit/`;
    const req = this.apiClient.post<ApiClientResponse<null>>(reqUrl);

    return this.makeRequest<null>(req, reqUrl);
  }

  addStoreCreditToCustomer(
    storeId: string,
    customerId: string,
    amount: string,
  ): Promise<null> {
    const reqUrl = `/payments/${storeId}/credit/add/`;
    const req = this.apiClient.post<ApiClientResponse<null>>(reqUrl, {
      amount,
      customer_id: customerId,
    });

    return this.makeRequest<null>(req, reqUrl);
  }
}
