import { BaseService } from "../../../services/base.service";
import {
  CreateStoreDto,
  CustomerOrderStats,
  MonthlyOrderStats,
  ProductPerfStats,
  Store,
  StoreDashboardConfig,
  StoreSetting,
  UpdateStoreSettingsDto,
} from "../../../models/store.model";
import { ApiClientResponse } from "../../../models/responses";
import { QueryOpts } from "../../../models/base.model";

export class StoreService extends BaseService {
  getStore(storeId: string): Promise<Store> {
    const reqUrl = `/stores/${storeId}`;
    const req = this.apiClient.get<ApiClientResponse<Store>>(reqUrl);
    return this.makeRequest<Store>(req, reqUrl);
  }

  createStore(storeDto: CreateStoreDto): Promise<Store> {
    const reqUrl = "/stores/create/";
    const req = this.apiClient.post<ApiClientResponse<Store>>(reqUrl, storeDto);

    return this.makeRequest<Store>(req, reqUrl);
  }

  getStoreProductPerfStats(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<ProductPerfStats> {
    const reqUrl = `/stores/${storeId}/stats/product_perf/`;
    const req = this.apiClient.get<ApiClientResponse<ProductPerfStats>>(
      reqUrl,
      {
        params: queryOpts.filters,
      },
    );
    return this.makeRequest<ProductPerfStats>(req, reqUrl);
  }

  getMonthlyOrderStats(
    storeId: string,
    year: string,
    month: string,
  ): Promise<MonthlyOrderStats> {
    const reqUrl = `/stores/${storeId}/stats/orders/${year}/${month}/`;
    const req =
      this.apiClient.get<ApiClientResponse<MonthlyOrderStats>>(reqUrl);
    return this.makeRequest<MonthlyOrderStats>(req, reqUrl);
  }

  getCustomerOrderStats(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<CustomerOrderStats> {
    const reqUrl = `/stores/${storeId}/stats/customers/`;
    const req = this.apiClient.get<ApiClientResponse<CustomerOrderStats>>(
      reqUrl,
      {
        params: queryOpts.filters,
      },
    );
    return this.makeRequest<CustomerOrderStats>(req, reqUrl);
  }

  getStoreSettings(storeId: string): Promise<StoreSetting> {
    const reqUrl = `/stores/${storeId}/settings/`;
    const req = this.apiClient.get<ApiClientResponse<StoreSetting>>(reqUrl);
    return this.makeRequest<StoreSetting>(req, reqUrl);
  }

  updateStoreSettings(
    storeId: string,
    data: UpdateStoreSettingsDto,
  ): Promise<StoreSetting> {
    const reqUrl = `/stores/${storeId}/settings/`;
    const req = this.apiClient.patch<ApiClientResponse<StoreSetting>>(
      reqUrl,
      data,
    );
    return this.makeRequest<StoreSetting>(req, reqUrl);
  }

  getStoreDashboardConfig(storeId: string): Promise<StoreDashboardConfig> {
    const reqUrl = `/stores/${storeId}/dashboard/`;
    const req =
      this.apiClient.get<ApiClientResponse<StoreDashboardConfig>>(reqUrl);
    return this.makeRequest<StoreDashboardConfig>(req, reqUrl);
  }
}
