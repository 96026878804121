import { useMutation, useQuery } from "react-query";
import {
  EcommerceUser,
  EcommerceUserCreateDto,
  EcommerceUserUpdateDto,
} from "./user.ecommerce.models";
import { EcommerceUserService } from "./user.ecommerce.service";

export const useCreateEcommerceUserMutation = () =>
  useMutation<EcommerceUser, Error, EcommerceUserCreateDto>((createUserDto) => {
    return EcommerceUserService.createUser(createUserDto);
  });

export const useUpdateEcommerceUserMutation = () =>
  useMutation<EcommerceUser, Error, EcommerceUserUpdateDto>((updateUserDto) => {
    return EcommerceUserService.updateUser(updateUserDto);
  });

export const useGetCurrentEcommerceUserQuery = () =>
  useQuery<EcommerceUser, Error>("getCurrentUser", () => {
    return EcommerceUserService.getCurrentUser();
  });
